<template>
  <div
    class="container top-0 position-sticky z-index-sticky"
    :style="{
      'background-image': 'url(' + BGGrid + ')',
      'background-size': 'cover',
    }"
  >
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>

  <section
    class="min-vh-80"
    :style="{
      'background-image': 'url(' + BGFeatures + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'contain',
      'background-position': 'center',
    }"
  >
    <div class="container">
      <div class="row h-100 d-flex justify-content-center">
        <div class="col-lg-5 col-md-7 mt-9">
          <div class="mt-4 card z-index-0">
            <div class="pt-4 pb-1 text-center card-header">
              <p class="alert alert-primary text-white" v-if="serverOkResponse">
                If a users exists with this email, account activation link will
                be sent.
              </p>
              <h4 class="mb-1 font-weight-bolder">Resend Activation Email</h4>
              <p class="mb-0">
                You should receive an activation e-mail within 5 minutes.
              </p>
            </div>
            <div class="card-body">
              <form role="form" @submit.prevent="resendActivationEmail()">
                <div class="mb-3">
                  <input
                    class="form-control"
                    type="email"
                    placeholder="Email"
                    name="email"
                    autocomplete="username"
                    v-model="email"
                    id="user-email"
                    required
                  />
                </div>

                <div class="d-flex justify-content-center mb-3 mt-4">
                  <RecaptchaV2
                    @widget-id="handleWidgetId"
                    @error-callback="handleErrorCallback"
                    @expired-callback="handleExpiredCallback"
                    @load-callback="handleLoadCallback"
                  />
                </div>

                <div class="form-check mt-4">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    name="flexCheckDefault"
                    v-model="checkedMail"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    I have checked my <b>inbox and spam folders </b> for
                    previously sent activation email.
                  </label>
                </div>

                <div class="text-center">
                  <div class="d-grid gap-2">
                    <button
                      class="my-2 mb-2 btn btn-dark btn-lg"
                      type="submit"
                      id="submitEmailButton"
                      :disabled="!checkedMail"
                    >
                      Resend Activation
                    </button>
                  </div>

                  <Toast />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <GlobalFooter />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import Toast from "primevue/toast";
import axios from "axios";
import { RecaptchaV2, useRecaptcha } from "vue3-recaptcha-v2";
const { handleReset } = useRecaptcha();
import BGGrid from "@/assets/img/bg-grid.svg";

import BGFeatures from "@/assets/img/bg-features.png";

import { mapMutations } from "vuex";
export default {
  name: "ResendActivationBasic",
  components: {
    Navbar,
    GlobalFooter,
    SoftButton,
    RecaptchaV2,
    Toast,
  },
  data() {
    return {
      email: "",
      captchaResponse: "",
      captchaResponseMessage: "",
      emailValidationError: "",
      validationMessages: "",
      serverOkResponse: false,
      BGGrid: BGGrid,
      BGFeatures: BGFeatures,
      widgetId: "",
      checkedMail: false,
    };
  },
  mounted() {},
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-200");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-200");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    handleWidgetId(widgetId) {
      this.widgetID = widgetId;
    },
    handleErrorCallback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleExpiredCallback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleLoadCallback(response) {
      // console.log("Load callback", response);
      this.captchaResponseMessage = response;
      this.captchaResponse = true;
    },

    validateEmail() {
      this.serverOkResponse = false;
      var regex = /^[a-zA-Z0-9_.+\-]+[\x40][a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.email)) {
        console.log("Email is invalid.");
        this.validationMessages.push("Email is invalid.");
        this.emailValidationError = true;
        document.getElementById("user-email").classList.add("is-invalid");
      } else {
        this.emailValidationError = false;
      }
    },
    resendActivationEmail() {
      document.getElementById("user-email").classList.remove("is-invalid");
      document.getElementById("user-email").classList.remove("is-valid");
      this.validationMessages = [];
      this.validateEmail();

      if (this.emailValidationError) {
        return;
      }

      var resetButton = document.getElementById("submitEmailButton");
      resetButton.disabled = true;
      resetButton.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
     <span>Please wait...</span>`;
      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/auth/resendActivationMail",
        data: {
          email: this.email,
          gRecaptchaResponse: this.captchaResponseMessage,
        },
      })
        .then((response) => {
          console.log(response);
          this.serverOkResponse = true;

          handleReset(this.widgetID);
          this.captchaResponseMessage = "";
          this.captchaResponse = false;

          resetButton.disabled = false;
          resetButton.innerHTML = `Resend Activation`;
          this.checkedMail = false;
        })
        .catch((error) => {
          console.log(error);

          handleReset(this.widgetID);
          this.captchaResponseMessage = "";
          this.captchaResponse = false;

          resetButton.disabled = false;
          resetButton.innerHTML = `Resend Activation`;

          document.getElementById("user-email").classList.remove("is-invalid");
          document.getElementById("user-email").classList.remove("is-valid");
          this.checkedMail = false;

          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: error.response.data.errors,
            life: 5000,
          });
        });
    },
  },
};
</script>
