<template>
  <div class="container mt-3 pt-2 dashboard-container-fluid">
    <div class="row">
      <div class="col-md-9">
        <h4><i class="fa fa-cog"></i> Settings</h4>
        <p class="text-sm">
          From this page you can update your organization profile.
        </p>
      </div>
      <div class="col-md-3 d-flex flex-row-reverse align-items-center h-100">
        <router-link to="/billing" class="btn btn-primary"
          ><i class="fa fa-dollar"></i> Goto Billing</router-link
        >
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-lg-3">
        <side-nav />
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4">
        <side-nav-item />
      </div>
    </div>
  </div>
</template>

<script>
import NavPill from "./components/NavPill.vue";
import SideNav from "./components/SideNav.vue";
import SideNavItem from "./components/SideNavItem.vue";
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "Settings",
  components: {
    NavPill,
    SideNav,
    SideNavItem,
  },
  mounted() {
    // this.$store.state.isAbsolute = false;
    // this.$store.state.isNavFixed = false;
    // setNavPills();
  },
};
</script>
