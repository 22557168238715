<template>
  <table class="table table-striped bg-white border-radius-lg">
    <thead class="">
      <th class="px-3 py-2 text-sm">Type</th>
      <th class="px-3 py-2 text-sm">Name</th>
      <th class="px-3 py-2 text-sm"><i class="fa fa-wifi"></i></th>
      <th class="px-3 py-2 text-sm">Status</th>
      <th class="px-3 py-2 text-sm">Client Version</th>
      <th class="px-3 py-2 text-sm">Last IP</th>
      <th class="px-3 py-2 text-sm">Last Online</th>
      <th class="px-3 py-2 text-sm">GPU Machine?</th>
      <th class="px-3 py-2 text-sm">xCP Earned</th>
      <th class="px-3 py-2 text-sm">Advanced</th>
    </thead>
    <tbody>
      <tr v-for="machine in machines" :key="machine._id">
        <td class="px-3 text-sm">
          <i :class="getOSIcon(machine.platform)"></i>
        </td>
        <td
          class="px-3 text-sm"
          v-tooltip.top="{
            value: machine.machineName,
            autoHide: false,
          }"
        >
          {{ machine.machineName }}
        </td>
        <td class="px-3 text-sm" v-if="deviceOnline(machine.lastOnline)">
          <span class="badge badge-success badge-sm text-dark">Online</span>
        </td>
        <td class="px-3 text-sm" v-else>
          <span class="badge badge-dark text-white badge-sm">Offline</span>
        </td>

        <td class="px-3 text-sm">
          <span
            class="badge text-dark badge-sm"
            :class="{
              'badge-success': machine.status == 'ENABLED',
              'badge-dark text-white': machine.status == 'INACTIVE',
              'badge-danger': machine.status == 'SUSPENDED',
            }"
            >{{ machine.status }}</span
          >
        </td>

        <td class="px-3 text-sm">
          {{ machine.clientVersion ? machine.clientVersion : "-" }}
        </td>
        <td
          class="px-3 text-sm"
          v-tooltip.top="{
            value: machine.ipAddress,
            autoHide: false,
          }"
        >
          {{ machine.ipAddress || "-" }}
        </td>
        <td class="px-3 text-sm">
          <span
            class="badge"
            :class="{
              'badge-success text-dark text-bolder': deviceOnline(
                machine.lastOnline
              ),
              'text-dark': !deviceOnline(machine.lastOnline),
            }"
          >
            {{
              machine.lastOnline
                ? convertMongoDateToLocale(machine.lastOnline)
                : "-"
            }}
          </span>
        </td>
        <td class="px-3 text-sm">{{ machine.isGPU ? "Yes" : "-" }}</td>
        <td class="px-3 text-sm">{{ machine.earnings.toLocaleString() }}</td>
        <td class="px-3 text-sm">
          <button class="btn btn-link m-0 p-0" @click="showDrawerData(machine)">
            Settings
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <Drawer v-model:visible="visibleRight" header="Settings" position="right">
    <div class="row py-2 border-bottom border-top">
      <div class="col">
        <h6><i class="fa fa-desktop"></i> Update Machine</h6>
        <p class="text text-sm">
          You can change the name and description of your machine.
        </p>

        <form
          id="addCreditsForm"
          role="form"
          @submit.prevent="updateMachine()"
          class="py-2 px-4"
        >
          <label for="machineName" class="form-label"
            >Machine Name (3-63 chars)</label
          >
          <div class="input-group mb-3">
            <input
              type="text"
              name="machineName"
              placeholder="Home Computer"
              class="form-control"
              id="machineName"
              v-model="updateMachineName"
              aria-label="Machine Name"
              autocomplete="off"
            />
          </div>

          <label for="machineDescription" class="form-label"
            >Machine Description (3-63 chars)</label
          >
          <div class="input-group mb-3">
            <input
              type="text"
              name="machineDescription"
              placeholder="Home computer with GPU RTX 3070, 16GB RAM..."
              class="form-control"
              id="machineDescription"
              v-model="updateMachineDescription"
              aria-label="Machine Description"
              autocomplete="off"
            />
          </div>

          <button
            type="submit"
            class="btn btn-primary btn-sm"
            id="updateMachine"
            :disabled="disableUpdateMachineBtn"
          >
            Update
          </button>
        </form>
      </div>
    </div>
    <div class="row py-2 border-bottom">
      <div class="col">
        <h6>
          <i class="fa fa-globe"></i>
          View Machine Token
        </h6>
        <p class="text-sm mb-0">
          Machine token can only be used once per machine.
        </p>

        <div class="py-4 text-center">
          <Inplace>
            <template #display>
              <i class="fa fa-warning"></i> Click to view
            </template>
            <template #content>
              <code class="my-4" style="overflow-wrap: break-word">
                {{ drawerData.machineToken }}
              </code>
            </template>
          </Inplace>
        </div>
      </div>
    </div>
    <div class="row py-2 border-bottom">
      <div class="col">
        <h6><i class="fa fa-trash"></i> Permanently delete machine</h6>
        <p class="text-sm">
          Delete the machine and all associated data. This action cannot be
          undone.
        </p>
        <a
          class="btn btn-danger btn-sm mt-2 d-block"
          data-bs-toggle="modal"
          data-bs-target="#deleteMachineModal"
          @click="showDeleteModal(drawerData)"
          >Delete Machine
        </a>
      </div>
    </div>
  </Drawer>

  <!-- Delete Machine Modal -->
  <Teleport to="body">
    <div
      class="modal"
      id="deleteMachineModal"
      aria-labelledby="deleteMachineModalLabel"
      aria-hidden="true"
      style="background-color: rgba(255, 0, 0, 0.2)"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="">Delete Machine?</h5>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-5 px-5">
            <h5 class="font-weight-bold text-danger text-center">
              <font-awesome-icon icon="triangle-exclamation" /> Warning!
            </h5>
            <p class="text-center">
              You are about to permanently delete
              <span class="text-danger"
                >{{ deleteModalData.machineName }}!</span
              >
              <br />Once deletion is initiated you will not be able to undo it.
              <br />
              Data associated with this Machine will be permanently deleted and
              cannot be recovered.
              <br />
            </p>
            <p class="font-weight-bold mt-4 pt-4">
              Type the Machine name ({{ deleteModalData.machineName }}) below to
              confrim:
            </p>
            <input
              class="form-control"
              type="text"
              name=""
              v-model="confirmText"
              placeholder="Enter machine name..."
            />
            <p class="text-danger text-sm" v-if="deleteConfirmError">
              You must enter the "Machine Name" above to confirm deletion.
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteMachine()"
            >
              Delete Machine
            </button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>

  <Toast />
</template>

<script>
import Drawer from "primevue/drawer";
import Inplace from "primevue/inplace";
import Toast from "primevue/toast";
import axios from "axios";
export default {
  name: "Machines",
  components: {
    Drawer,
    Inplace,
    Toast,
  },
  props: {
    machines: {
      type: Array,
    },
  },
  data() {
    return {
      visibleRight: false,
      drawerData: {},
      deleteModalData: {},
      updateMachineName: "",
      updateMachineDescription: "",
      disableUpdateMachineBtn: false,
      deleteConfirmError: false,
      confirmText: "",
    };
  },
  methods: {
    showDrawerData(machine) {
      this.drawerData = machine;
      this.updateMachineName = machine.machineName;
      this.updateMachineDescription = machine.machineDescription;
      this.visibleRight = true;
    },
    updateMachine() {
      this.disableUpdateMachineBtn = true;
      axios({
        method: "post",
        url:
          this.$store.getters["getServerURL"] +
          "/org/rentCompute/updateMachineById",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          machineName: this.updateMachineName,
          machineDescription: this.updateMachineDescription,
          machineId: this.drawerData._id,
        },
      })
        .then((resp) => {
          // console.log(resp.data);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Machine updated! Reloading page...",
            life: 2000,
          });

          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 2000);
        })
        .catch((err) => {
          this.disableUpdateMachineBtn = false;
          handleReset(this.widgetID);
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Error while adding a new machine. \n" +
              err.response.data.errors[0] +
              (err.response.data.errors[1]
                ? "\n" + err.response.data.errors[1]
                : ""),
            life: 5000,
          });
        });
    },
    showDeleteModal(machine) {
      this.deleteModalData = machine;
    },
    deleteMachine() {
      this.deleteConfirmError = false;
      if (this.deleteModalData.machineName != this.confirmText) {
        this.deleteConfirmError = true;
        return;
      }

      axios({
        method: "delete",
        url:
          this.$store.getters["getServerURL"] +
          "/org/rentCompute/deleteMachineById/" +
          this.deleteModalData._id,
        headers: { Authorization: localStorage.getItem("jwt") },
      })
        .then((response) => {
          // console.log(response);
          location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Error while deleting the machine. \n" + err.response.data.error,
            life: 5000,
          });
        });
    },
    deviceOnline(lastOnline) {
      const lastUpdated = new Date(lastOnline);
      const twoMinutesAgo = new Date(Date.now() - 2 * 62 * 1000);

      return lastUpdated > twoMinutesAgo;
    },
    getOSIcon(platform) {
      switch (platform) {
        case "LINUX":
          return "fa fa-linux fa-2x";
        case "MAC":
        case "DARWIN": // macOS and Darwin share the same icon
          return "fa fa-apple fa-2x";
        case "WIN32":
          return "fa fa-windows fa-2x";
        default:
          return "fa fa-server fa-2x"; // No icon for unknown OS
      }
    },
    convertMongoDateToLocale(mongoDate) {
      const dateObj = new Date(mongoDate);
      // Format the date with timezone information (short format, e.g., "EST")
      const formattedDate = dateObj.toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
      });
      return formattedDate;
    },
  },
};
</script>
