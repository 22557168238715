<template>
  <div class="container mt-4">
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="font-weight-bolder">
              <a href="/admin/users"
                ><i class="fa fa-arrow-left"></i> Back to All Users</a
              >
            </p>

            <p>
              <b>Administration / View User -</b>
              {{ this.$route.params.userID }} |
              {{ completeProfile.user.email }}
            </p>

            <p><b>Org Name:</b> {{ completeProfile.org.orgName || "-" }}</p>
            <p><b>Org About:</b> {{ completeProfile.org.orgAbout || "-" }}</p>
            <p>
              <b>Address:</b> {{ completeProfile.org.orgAddress || "-" }},
              {{ completeProfile.org.orgCountry || "-" }}
            </p>
            <p>
              <b>computeEarnings xCP:</b>
              {{ completeProfile.user.computeEarnings }}
            </p>
            <p>
              <b>referralEarnings xCP:</b>
              {{ completeProfile.user.referralEarnings }}
            </p>
            <p>
              <b>badgeEarnings xCP:</b>
              {{ completeProfile.user.badgeEarnings }}
            </p>
            <router-link
              class="btn btn-primary"
              :to="'/admin/users/manage-credits/' + this.$route.params.userID"
              >Manage Credits</router-link
            >
            <button
              class="btn btn-danger mx-2"
              v-if="completeProfile.user.active == 1"
              @click="updateUser(0)"
            >
              Deactivate user
            </button>
            <button class="btn btn-success mx-2" v-else @click="updateUser(1)">
              Activate User
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5 mt-4">
      <div class="col-md-12" data-aos="fade-up">
        <div class="card">
          <div class="card-body py-4">
            <div class="row">
              <div class="col-md-12">
                <p>All Apps</p>
              </div>
              <div class="col-md-4 mt-4" v-for="app in apps" :key="app._id">
                <AppCardAdmin :app="app" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5 mt-4">
      <div class="col-md-12" data-aos="fade-up">
        <div class="card">
          <div class="card-body py-4">
            <div class="row">
              <div class="col-md-12">
                <p>All Machines</p>
              </div>

              <Machines :machines="machines" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5 mt-4">
      <div class="col-md-12" data-aos="fade-up">
        <div class="card">
          <div class="card-body py-4">
            <div class="row">
              <div class="col-md-12">
                <p>All Referrals</p>
              </div>

              <ReferralTable :referrals="referrals" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5 mt-4">
      <div class="col-md-12" data-aos="fade-up">
        <div class="card">
          <div class="card-body py-4">
            <div class="row">
              <div class="col-md-12">
                <p>All Invoices</p>
              </div>
              <InvoiceCard :invoices="invoices" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast></Toast>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
import AppCardAdmin from "./components/AppCardAdmin.vue";
import InvoiceCard from "../../../pages/Account/components/InvoiceCard.vue";
import Toast from "primevue/toast";
import ReferralTable from "../../../achievements/components/ReferralTable.vue";
import Machines from "../../../rentMachineDashboard/components/Machines.vue";

export default {
  name: "ViewUser",
  components: {
    AppCardAdmin,
    InvoiceCard,
    Toast,
    ReferralTable,
    Machines,
  },
  data() {
    return {
      invoices: [],
      apps: [],
      completeProfile: {
        user: {
          email: "",
        },
        org: {},
      },
      referrals: [],
      machines: [],
    };
  },
  mounted() {
    // this.$store.state.isAbsolute = false;
    // this.$store.state.isNavFixed = false;
    // setNavPills();
    this.getUser();
    this.getApps();
    this.getInvoices();
    this.getReferrals();
    this.getMachines();
  },
  methods: {
    updateUser(state) {
      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/admin/updateUser",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          userID: this.completeProfile.user._id,
          active: state,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: this.completeProfile.user.email + " Updated!",
            life: 2000,
          });
          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getReferrals() {
      axios
        .get(
          this.$store.getters["getServerURL"] +
            "/referral/getAllReferrals/" +
            this.$route.params.userID,
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.referrals = response.data;
          this.referrals.reverse();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getInvoices() {
      axios
        .get(
          this.$store.getters["getServerURL"] +
            "/admin/getAllInvoices/" +
            this.$route.params.userID,
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          this.invoices = response.data;
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getApps() {
      axios
        .get(
          this.$store.getters["getServerURL"] +
            "/admin/getAllApps/" +
            this.$route.params.userID,
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          this.apps = response.data;
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMachines() {
      axios
        .get(
          this.$store.getters["getServerURL"] +
            "/org/rentCompute/getAllMachines/" +
            this.$route.params.userID,
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          console.log(response);
          this.machines = response.data;
          this.machines.reverse();
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getUser() {
      axios
        .get(
          this.$store.getters["getServerURL"] +
            "/admin/getUser/" +
            this.$route.params.userID,
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          this.completeProfile = response.data;

          console.log(this.completeProfile);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
