<template>
  <navbar btn-background="bg-gradient-primary" />
  <div
    class="pt-5 page-header align-items-start min-vh-40 pb-11"
    :style="{
      'background-image': 'url(' + BGGrid + ')',
      'background-size': 'cover',
      'background-position': 'top',
    }"
  >
    <span class="mask opacity-10" style="background-color: darkblue"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h1 class="mt-2 pt-5 mb-2 text-white">👋 Hi there!</h1>
          <p class="text-white text-lead">
            Sing Up for Exa Protocol with your Google Account or Email - normie
            friendly login!
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10">
      <div class="col-md-4">
        <div class="card z-index-0">
          <div class="card-body my-2">
            <div class="text-center">
              <div class="d-flex justify-content-center">
                <div
                  id="g_id_onload"
                  :data-client_id="googleClientId"
                  data-context="signup"
                  data-ux_mode="popup"
                  data-itp_support="true"
                  data-cancel_on_tap_outside="false"
                  data-skip_prompt_cookie="google-signin-skipped"
                  data-callback="handleCredentialResponse"
                  data-auto_prompt="false"
                ></div>

                <div
                  class="g_id_signin"
                  data-type="standard"
                  data-shape="rectangular"
                  data-theme="outline"
                  data-text="signup_with"
                  data-size="large"
                  data-logo_alignment="center"
                ></div>
              </div>
              <div class="mt-3 text-xs text-muted">
                By signing up with Google, you agree to Exa Protocol's
                <a href="/tos" target="_blank">Terms of Use</a> and
                <a href="/privacy" target="_blank">Privacy Policy</a>.
              </div>
            </div>
            <div class="my-3 text-center position-relative">
              <p
                class="px-3 mb-2 text-sm bg-white font-weight-bold text-secondary text-border d-inline z-index-2"
              >
                or
              </p>
            </div>
            <p class="text-center text-sm text-bold text-dark">
              Sign up with email and password
            </p>
            <form role="form" @submit.prevent="signUp()">
              <div class="mb-3">
                <input
                  id="name"
                  type="text"
                  placeholder="Enter your company name"
                  aria-label="Name"
                  name="name"
                  class="form-control"
                  autocomplete="name"
                  v-model="name"
                  required
                />
              </div>
              <div class="mb-3">
                <input
                  id="email"
                  type="email"
                  placeholder="Your work email"
                  aria-label="Email"
                  class="form-control"
                  autocomplete="username"
                  name="email"
                  v-model="email"
                  required
                />
              </div>
              <div class="mb-3">
                <input
                  id="password"
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  class="form-control"
                  name="password"
                  autocomplete="new-password"
                  maxlength="32"
                  minlength="8"
                  v-model="password"
                  required
                />
              </div>

              <div class="form-check mb-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  name="flexCheckDefault"
                  v-model="tosConsent"
                />
                <label class="form-check-label text-xs" for="flexCheckDefault">
                  I agree with the
                  <a
                    href="/privacy"
                    class="text-dark font-weight-bolder"
                    target="_blank"
                    >Privacy Policy</a
                  >
                  &
                  <a
                    href="/tos"
                    class="text-dark font-weight-bolder"
                    target="_blank"
                    >Terms of Use</a
                  >
                </label>
              </div>

              <div class="d-flex justify-content-center mb-3 mt-4">
                <RecaptchaV2
                  @widget-id="handleWidgetId"
                  @error-callback="handleErrorCalback"
                  @expired-callback="handleExpiredCallback"
                  @load-callback="handleLoadCallback"
                />
              </div>

              <ul v-if="validationMessages.length > 0" class="my-3">
                <li
                  v-for="message in validationMessages"
                  :key="message"
                  class="text-danger text-sm"
                >
                  {{ message }}
                </li>
              </ul>
              <div class="text-center">
                <soft-button
                  color="primary"
                  full-width
                  variant=""
                  class="my-2"
                  type="submit"
                  >Sign up</soft-button
                >
              </div>
              <p class="text-sm mt-3 mb-0 text-center">
                Already have an account?
                <router-link
                  :to="{ name: 'Signin Basic' }"
                  class="text-dark font-weight-bolder"
                >
                  Sign in
                </router-link>
              </p>
            </form>

            <Toast />
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card h-100 p-4">
          <div class="card-body p-0">
            <div v-if="referredBy" class="d-flex h-100">
              <div class="text-center p-4 align-self-center mx-auto">
                <Avatar :size="80" variant="beam" :name="referredBy.name" />
                <h3 class="mt-3">You got referred! 🎉</h3>
                <p class="text-lg">
                  <b>{{ referredBy.name }}</b> invited you to join Exa Protocol.
                </p>
                <hr class="horizontal dark" />

                <p>
                  You both will earn <b>2500 compute points</b> after you
                  successfully rent your machine!
                </p>

                <button class="btn btn-link mt-4" @click="removeReferral()">
                  I don't want this referral
                </button>
              </div>
            </div>
            <div
              v-else
              class="d-flex h-100 border-radius-lg"
              :style="{
                'background-image': !referredBy
                  ? 'url(' + require('@/assets/img/singup-ghibli.png') + ')'
                  : 'none',
                'background-size': 'cover',
                'background-position': 'left',
              }"
            ></div>
            <!-- <img
              v-else
              :src="require('@/assets/img/singup-ghibli.png')"
              alt=""
              class="img-fluid"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <GlobalFooter />
</template>

<script>
import Navbar from "@/components/PageLayout/Navbar.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";

import { mapMutations } from "vuex";
import axios from "axios";
import Toast from "primevue/toast";
import { RecaptchaV2, useRecaptcha } from "vue3-recaptcha-v2";
const { handleReset } = useRecaptcha();
import Brands from "../../home/componentrs/Brands.vue";
import BGGrid from "@/assets/img/bg-grid.svg";
import Avatar from "vue-boring-avatars";
import TierLadder from "../../home/componentrs/TierLadder.vue";
import config from "@/config.js";

export default {
  name: "SignupBasic",
  components: {
    Navbar,
    GlobalFooter,
    SoftCheckbox,
    SoftButton,
    RecaptchaV2,
    Toast,
    Brands,
    Avatar,
    TierLadder,
  },
  data() {
    return {
      BGGrid: BGGrid,
      captchaResponse: "",
      captchaResponseMessage: "",
      name: "",
      email: "",
      password: "",
      tosConsent: false,
      validationMessages: [],
      emailValidationError: false,
      passwordValidationError: false,
      widgetID: "",
      referredBy: null,
      googleClientId: config.googleClientId,
      googleScriptLoaded: false,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  activated() {
    // This lifecycle hook triggers when a component is re-activated via vue-router
    // Always reinitialize when the component is re-activated
    window.handleCredentialResponse = this.handleCredentialResponse;
    this.initializeGoogleSignIn();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  async mounted() {
    if (localStorage.getItem("jwt")) {
      this.$router.push("/dashboard");
    }
    localStorage.removeItem("referralName");

    if (this.$route.query.referredBy || localStorage.getItem("referredBy")) {
      try {
        var response = await axios.get(
          this.$store.getters["getServerURL"] +
            "/referral/validateReferral/" +
            (this.$route.query.referredBy
              ? this.$route.query.referredBy
              : localStorage.getItem("referredBy"))
        );
        console.log(response);
        this.referredBy = response.data;
        localStorage.setItem(
          "referredBy",
          this.$route.query.referredBy
            ? this.$route.query.referredBy
            : localStorage.getItem("referredBy")
        ); //set referredBy to local storage
        this.$confetti.start();
        localStorage.setItem("referralName", response.data.name);
        setTimeout(() => {
          this.$confetti.stop();
        }, 2000);
      } catch (err) {
        console.log(err);
        localStorage.removeItem("referredBy");
        localStorage.removeItem("referralName");
        this.$toast.add({
          severity: "info",
          summary: "Attention!",
          detail: "Invalid referral code!",
          life: 3000,
        });
      }
    }

    // Set up global callback for Google Sign-In
    window.handleCredentialResponse = this.handleCredentialResponse;

    // Initialize Google Sign-In without loading the script (already in index.html)
    this.initializeGoogleSignIn();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    handleWidgetId(widgetId) {
      // console.log("Widget ID: ", widgetId);
      this.widgetID = widgetId;
    },
    handleErrorCalback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleExpiredCallback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleLoadCallback(response) {
      // console.log("Load callback", response);
      this.captchaResponseMessage = response;
      this.captchaResponse = true;
    },
    validateEmail() {
      var regex = /^[a-zA-Z0-9_.+\-]+[\x40][a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.email)) {
        console.log("Email is invalid.");
        this.validationMessages.push("Email is invalid.");
        this.emailValidationError = true;
      } else {
        this.emailValidationError = false;
      }
    },
    validatePassword() {
      var passwordVal = true;
      if (this.password.length < 8 || this.password.length > 32)
        passwordVal = false;

      if (!passwordVal) {
        this.validationMessages.push(
          "Password is invalid. \nPassword must be between 8 and 32 characters."
        );
        this.passwordValidationError = true;
      } else {
        this.passwordValidationError = false;
      }
    },
    validateTosConsent() {
      if (!this.tosConsent) {
        this.validationMessages.push("You must agree to terms and conditions.");
      }
    },
    signUp() {
      this.validationMessages = [];
      this.emailValidationError = false;
      this.passwordValidationError = false;

      this.validateEmail();
      this.validatePassword();
      this.validateTosConsent();

      if (
        this.emailValidationError ||
        this.passwordValidationError ||
        !this.tosConsent
      ) {
        return;
      }

      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/auth/signup",
        data: {
          name: this.name,
          email: this.email,
          password: this.password,
          gRecaptchaResponse: this.captchaResponseMessage,
          referredBy: this.referredBy ? this.referredBy.referralCode : null,
          ref: this.$route.query.ref || null,
        },
      })
        .then((response) => {
          console.log(response);
          localStorage.removeItem("referredBy");
          this.$router.push("/authentication/signin/?register=true");
        })
        .catch((error) => {
          // console.log(error);
          handleReset(this.widgetID);
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: error.response.data.errors,
            life: 5000,
          });
        });
    },
    removeReferral() {
      localStorage.removeItem("referredBy");
      localStorage.removeItem("referralName");
      window.location.href = window.location.origin + window.location.pathname;
    },

    async handleCredentialResponse(response) {
      try {
        // If the response came from a popup and has credential property
        const idToken = response.credential;

        // Add referral information to the request if available
        const data = {
          accessToken: idToken,
        };

        if (this.referredBy) {
          data.referredBy = this.referredBy.referralCode;
        }

        if (this.$route.query.ref) {
          data.ref = this.$route.query.ref;
        }

        const result = await axios({
          method: "post",
          url: this.$store.getters["getServerURL"] + "/auth/signin/google",
          data: data,
        });

        const user = result.data;
        if (user.registered) {
          localStorage.removeItem("referredBy");
          this.$router.push("/authentication/signin/?register=true");
        } else if (user.loggedIn) {
          localStorage.setItem("jwt", user.token);
          localStorage.setItem("loggedIn", "true");
          localStorage.setItem("expiresIn", user.expiresIn || "30d");
          localStorage.setItem("userID", user.user.userID);
          localStorage.setItem("userName", user.user.name);
          localStorage.setItem("role", user.user.role);
          localStorage.removeItem("referredBy");
          localStorage.removeItem("referralName");
          this.$router.push("/dashboard");
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Google Sign-Up Failed",
            detail: user.message || "Authentication failed. Please try again.",
            life: 3000,
          });
        }
      } catch (error) {
        console.error("Google Sign-In error:", error);
        // If we have COOP issues, provide a fallback
        if (error.message && error.message.includes("Cross-Origin")) {
          this.$toast.add({
            severity: "warning",
            summary: "Authentication Issue",
            detail:
              "Please try using the regular email sign-up method instead.",
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Google Sign-Up Error",
            detail:
              error.response?.data?.message ||
              "An error occurred. Please try again.",
            life: 3000,
          });
        }
      }
    },

    signUpWithGoogle() {
      // Also pass along any referral information
      let url = this.$store.getters["getServerURL"] + "/auth/signin/google";

      // Add referral code if available
      if (this.referredBy) {
        url += `?referredBy=${this.referredBy.referralCode}`;
      }

      // Add ref parameter if available
      if (this.$route.query.ref) {
        url += (url.includes("?") ? "&" : "?") + `ref=${this.$route.query.ref}`;
      }

      window.location.href = url;
    },

    initializeGoogleSignIn() {
      // Wait a bit for the Google API to be ready
      setTimeout(() => {
        if (
          !window.google ||
          !window.google.accounts ||
          !window.google.accounts.id
        ) {
          console.log("Google Sign-In API not yet available, waiting...");
          setTimeout(() => this.initializeGoogleSignIn(), 200); // Retry after a delay
          return;
        }

        try {
          window.google.accounts.id.initialize({
            client_id: this.googleClientId,
            callback: this.handleCredentialResponse,
            context: "signup",
            ux_mode: "popup",
            itp_support: true,
            cancel_on_tap_outside: false,
            use_fedcm_for_prompt: true,
          });

          // Ensure the button element exists before trying to render
          const signInButton = document.querySelector(".g_id_signin");
          if (signInButton) {
            window.google.accounts.id.renderButton(signInButton, {
              type: "standard",
              shape: "rectangular",
              theme: "outline",
              text: "signup_with",
              size: "large",
              logo_alignment: "center",
            });
            console.log("Google Sign-In button rendered successfully");
            this.googleScriptLoaded = true;
          } else {
            console.error("Google Sign-In button container not found");
          }
        } catch (error) {
          console.error("Error initializing Google Sign-In:", error);
          this.$toast.add({
            severity: "error",
            summary: "Authentication Error",
            detail:
              "Could not initialize Google authentication. Please try using email signup.",
            life: 5000,
          });
        }
      }, 100);
    },
  },
};
</script>

<style>
.signup-anim {
  height: 90%;
  width: 100%;
}
</style>
