<template>
  <!-- referral list -->

  <div class="row">
    <div class="col-md-10">
      <h5 class="font-weight-bolder">Your Referrals</h5>
      <p class="text-sm">
        Earn 2,500 Exa Compute Points (xCP) for every successful referral! Plus,
        enjoy a 0.5% commission on their earned xCP—forever!
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12" v-if="referrals.length > 0">
      <div class="table-responsive">
        <table class="table bg-white table-striped border-radius-lg">
          <thead class="">
            <th class="px-3 py-2 text-sm">Name</th>
            <th class="px-3 py-2 text-sm">Referral Time</th>
            <th class="px-3 py-2 text-sm">Machine Rented?</th>
            <th class="px-3 py-2 text-sm">Reward Redemption Date</th>
            <th class="px-3 py-2 text-sm">Referral Points Earned</th>
          </thead>
          <tbody>
            <tr v-for="referral in referrals" :key="referral._id">
              <td class="px-3 text-sm">
                {{ referral.referral.name }}
                <template v-if="isAdmin()">
                  <p class="text-xs">
                    <a :href="'/admin/users/view/' + referral.referral._id">
                      {{ referral.referral._id }}</a
                    >
                  </p>
                </template>
              </td>
              <td class="px-3 text-sm">
                <span class="badge badge-primary text-dark">
                  {{ convertMongoDateToLocale(referral.referral.createdAt) }}
                </span>
              </td>
              <td
                class="px-3 text-sm"
                v-tooltip.bottom="{
                  value: referral.machineRented
                    ? 'Earned the referral reward'
                    : 'To earn 2500 xCP, ask them to connect their machine and activate it.',
                  autoHide: false,
                }"
              >
                {{ referral.machineRented ? "✅" : "❌" }}
              </td>
              <td class="px-3 text-sm">
                <span
                  class="badge badge-primary text-dark"
                  v-if="referral.redemptionTimestamp"
                >
                  {{ convertMongoDateToLocale(referral.redemptionTimestamp) }}
                </span>
                <template v-else>❌</template>
              </td>
              <td class="px-3 text-sm">
                {{ referral.machineRented ? (2500).toLocaleString() : 0 }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-md-12" v-else>
      <div class="card mt-4">
        <div
          class="card-body w-100 text-center justify-content-center align-self-center py-6"
        >
          <h4><i class="fa-solid fa-users-viewfinder fa-2x"></i></h4>
          <h4 class="h-100">No referrals!</h4>
          <p class="mb-0">People you have referred will appear here.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Toast from "primevue/toast";
import axios from "axios";
export default {
  name: "Referral Table",
  props: {
    referrals: {
      type: Array,
    },
  },
  components: {
    Toast,
  },
  mounted() {},
  data() {},
  methods: {
    isAdmin() {
      return localStorage.getItem("role") === "1" ? true : false;
    },
    convertMongoDateToLocale(mongoDate) {
      const dateObj = new Date(mongoDate);
      // Format the date with timezone information (short format, e.g., "EST")
      const formattedDate = dateObj.toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
      });
      return formattedDate;
    },
  },
};
</script>
