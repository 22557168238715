<template>
  <div>
    <sidenav v-if="showSidenav" :custom-class="color" class="fixed-start" />
    <main class="main-content position-relative">
      <!-- nav -->
      <navbar
        v-if="showNavbar"
        :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
        :min-nav="navbarMinimize"
      />
      <router-view />

      <app-footer v-show="showFooter" />
    </main>
  </div>
</template>
<script>
import Sidenav from "@/components/Sidenav";
import Navbar from "@/components/Navbars/Navbar.vue";
import AppFooter from "@/components/PageLayout/Footer.vue";
import { mapMutations, mapState } from "vuex";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
  },

  computed: {
    ...mapState([
      "isTransparent",
      "isRTL",
      "isNavFixed",
      "isAbsolute",
      "navbarFixed",
      "absolute",
      "color",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-white";
  },
  mounted() {
    AOS.init({ once: true });
  },

  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
};
</script>
<style>
.card {
  /* border-radius: 10px !important; */
  border: 1px solid #ddd !important;
}

@-webkit-keyframes blue-fade {
  from {
    background: rgb(201, 223, 255);
  }
  to {
    background: #fff;
  }
}
@-moz-keyframes blue-fade {
  from {
    background: rgb(201, 223, 255);
  }
  to {
    background: #fff;
  }
}
@keyframes blue-fade {
  from {
    background: rgb(201, 223, 255);
  }
  to {
    background: #fff;
  }
}
.fade-it {
  -webkit-animation: blue-fade 1s ease-in-out 0s;
  -moz-animation: blue-fade 1s ease-in-out 0s;
  -o-animation: blue-fade 1s ease-in-out 0s;
  animation: blue-fade 1s ease-in-out 0s;
}

.dashboard-container-fluid {
  min-height: 70vh;
}
.p-toast {
  z-index: 1000;
}

.IDLabel {
  text-transform: uppercase;
  color: #ccc;
  font-weight: bold;
}

.badge-dark {
  background-color: #444 !important;
}

#confetti-canvas {
  z-index: 10000;
}

:root {
  --primary-color: #007ad9;
  --primary-color-text: #ffffff;
  --surface-color: #ffffff;
  --surface-border: #cccccc;
  --text-color: #333333;
  --text-secondary-color: #555555;
  --background-color: #f4f4f4;
}

.btn-xs {
  font-size: 0.6rem !important;
  padding: 5px !important;
}

.highlight-card:hover {
  background-color: rgba(0, 0, 255, 0.1) !important;
  border: 1px solid blue !important;
}

.bg-achievements {
  background-color: #ff9f40;
}

.text-achievements {
  color: #ff9f40;
}

.btn-outline-achievements {
  color: #ff9f40 !important;
  border-color: #ff9f40 !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #7a84f8 !important;
  outline: 0;
  box-shadow: 0 0 0 2px #7a84f8 !important;
}

.form-switch .form-check-input:checked {
  border-color: rgba(15, 152, 0, 0.95) !important;
  background-color: rgb(16 157 0 / 95%) !important;
}

.ribbon-sm {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
.ribbon-sm {
  --f: 0.4em; /* control the folded part*/
  --r: 0.4em; /* control the ribbon shape */

  position: absolute;
  top: 10px;
  right: calc(-1 * var(--f));
  padding-inline: 1em;

  line-height: 1.8;
  /* background: #1f54ff; */
  border-bottom: var(--f) solid #0005;
  border-left: var(--r) solid #0000;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
}
</style>
