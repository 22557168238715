<template>
  <div
    class="card h-100"
    style="cursor: pointer"
    data-bs-toggle="modal"
    data-bs-target="#viewUserTiersModal"
  >
    <div class="card-body py-2">
      <div class="row h-100 d-flex align-items-center">
        <div class="col-4">
          <img
            :src="'images/badges/tier' + calculateUserTier() + '.png'"
            alt=""
            class="img-fluid"
            v-tooltip.top="{
              value: 'Tier ' + calculateUserTier(),
              autoHide: false,
            }"
          />
        </div>
        <div class="col-8">
          <h4 class="text-center mt-2">
            {{ parseUserTier(calculateUserTier()) }}
          </h4>
          <div class="progress mt-2">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              :style="{ width: getProgressToNextTier() + '%' }"
              :aria-valuenow="getProgressToNextTier()"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <p class="text-xs mt-2">
            Next level in {{ getPointsToNextTier() }} xCP
          </p>
        </div>
      </div>
    </div>
    <div class="card-footer bg-dark p-0 m-0">
      <p
        v-if="calculateUserTier() > 1"
        class="text-xs text-light font-weight-bolder p-0 py-2 text-center m-0"
        v-tooltip.bottom="{
          value: 'From Tier 1',
          autoHide: false,
        }"
      >
        Compute Earning Bonus: +{{ getTierBenefit() }}%
      </p>
      <p
        v-else
        class="text-xs text-light font-weight-bold p-0 py-2 text-center m-0"
      >
        Compute Earning Baseline
      </p>
    </div>
  </div>

  <!-- View User Tiers -->
  <Teleport to="body">
    <div
      class="modal"
      id="viewUserTiersModal"
      aria-labelledby="viewUserTiersLabel"
      aria-hidden="true"
      style="background-color: rgba(0, 0, 0, 0.2)"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content p-0 m-0">
          <div class="modal-body p-0 m-0 text-center">
            <img
              :src="'images/badges/tier' + calculateUserTier() + '.png'"
              alt=""
              class="user-badge-xl user-badge-top-sticker"
              v-tooltip.top="{
                value: 'Current Tier: ' + parseUserTier(calculateUserTier()),
                autoHide: false,
              }"
            />
            <div class="row pt-6 mt-2 pb-4 px-4 d-flex align-items-center">
              <div class="col-12 text-center">
                <h3 class="text-center mt-2 text-light text-dark py-2">
                  Account Tier:
                  <span
                    class="bg-light font-weight-bold text-dark px-2 border-radius-lg"
                    >{{ parseUserTier(calculateUserTier()) }}
                  </span>
                </h3>
                <div class="progress mt-2">
                  <div
                    class="progress-bar bg-dark"
                    role="progressbar"
                    :style="{ width: getProgressToNextTier() + '%' }"
                    :aria-valuenow="getProgressToNextTier()"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-sm mt-2">
                  <b> Next tier in {{ getPointsToNextTier() }} xCP.</b>
                  <br />
                  Keep your machine online, refer people, or execute compute
                  task to earn xCP!
                  <a href="/compute-rent" class="text-primary bg-light px-2"
                    >Rent Now!</a
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <table
                  class="table table m-0 p-0"
                  style="
                    text-align: left !important;
                    height: 200px;
                    overflow-y: scroll;
                  "
                >
                  <thead class="bg-dark text-white">
                    <th>Tier</th>
                    <th>Name</th>
                    <th>Benefit</th>
                    <th>xCP required</th>
                    <th>Status</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="tier in userTiers"
                      :key="tier._id"
                      :class="{
                        'text-bolder bg-success-light':
                          calculateUserTier() >= tier.tier,
                        'text-bolder': calculateUserTier() == tier.tier,
                      }"
                      class="text-sm"
                    >
                      <td class="px-4">
                        {{ tier.tier }}
                        {{
                          calculateUserTier() == tier.tier ? "(Current)" : ""
                        }}
                      </td>

                      <td class="px-4">
                        {{ tier.name }}
                      </td>
                      <td class="px-4">
                        <p
                          v-if="tier.tier > 1"
                          class="badge badge-light text-dark p-1 m-0"
                          v-tooltip.right="{
                            value: 'From Tier 1',
                            autoHide: false,
                          }"
                        >
                          {{ tier.tierBenefit + "% Earning bonus" }}
                        </p>
                        <p class="badge badge-light text-dark p-1 m-0" v-else>
                          Baseline
                        </p>
                      </td>
                      <td class="px-4">
                        {{ tier.pointsRequired.toLocaleString() }}
                      </td>
                      <td
                        class="px-4"
                        v-tooltip.right="{
                          value:
                            calculateUserTier() >= tier.tier
                              ? 'Unlocked'
                              : 'Locked',
                          autoHide: false,
                        }"
                      >
                        {{ calculateUserTier() >= tier.tier ? "✅" : "🔒" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script>
import axios from "axios";
export default {
  name: "CurrentUserTier",
  props: {
    earnings: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      userTiers: [],
    };
  },
  mounted() {
    console.log(this.earnings, this.userTier);
    this.getUserTiers();
  },
  methods: {
    getUserTiers() {
      axios
        .get(
          this.$store.getters["getServerURL"] + "/org/rentCompute/getUserTiers",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((resp) => {
          // console.log("tiers", resp.data);
          this.userTiers = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPointsToNextTier() {
      var currentPoints = this.earnings;
      for (let i = 0; i < this.userTiers.length - 1; i++) {
        if (currentPoints < this.userTiers[i + 1].pointsRequired) {
          return this.userTiers[i + 1].pointsRequired - currentPoints;
        }
      }
      console.log(currentPoints);
      return 0; // User is already at the highest tier
    },
    getProgressToNextTier() {
      var currentPoints = this.earnings;
      for (let i = 0; i < this.userTiers.length - 1; i++) {
        if (currentPoints < this.userTiers[i + 1].pointsRequired) {
          const prevTierPoints = this.userTiers[i].pointsRequired;
          const nextTierPoints = this.userTiers[i + 1].pointsRequired;
          const progress =
            ((currentPoints - prevTierPoints) /
              (nextTierPoints - prevTierPoints)) *
            100;
          return Math.min(Math.max(progress, 0), 100); // Ensure the range is 0-100%
        }
      }
      return 100; // If the user is at the highest tier, return 100%
    },
    parseUserTier(tier) {
      switch (tier) {
        case 1:
          return "Scout";
        case 2:
          return "Seeker";
        case 3:
          return "Pathfinder";
        case 4:
          return "Voyager";
        case 5:
          return "Pioneer";
        case 6:
          return "Trailblazer";
        case 7:
          return "Navigator";
        case 8:
          return "Discoverer";
        case 9:
          return "Conqueror";
        case 10:
          return "Legend";
        default:
          return "Invalid Tier"; // Handles out-of-range inputs
      }
    },
    getTierBenefit() {
      for (var i = 0; i < this.userTiers.length; i++) {
        if (this.userTiers[i].tier == this.calculateUserTier()) {
          return this.userTiers[i].tierBenefit;
        }
      }
    },
    calculateUserTier() {
      var currentPoints = this.earnings;
      var tiers = this.userTiers;
      // Make a shallow copy and sort tiers by pointsRequired (ascending)
      const sortedTiers = tiers
        .slice()
        .sort((a, b) => a.pointsRequired - b.pointsRequired);

      // Start with the lowest tier (assumes at least one tier exists)
      let userTier = 1;

      // Iterate through sorted tiers; update userTier if currentPoints meets the requirement
      for (let i = 0; i < sortedTiers.length; i++) {
        if (currentPoints >= sortedTiers[i].pointsRequired) {
          userTier = sortedTiers[i].tier;
        } else {
          // Since the array is sorted, we can exit early
          break;
        }
      }

      return userTier;
    },
  },
};
</script>

<style>
.glow-box::after {
  position: absolute;
  content: "";
  top: 0vw;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.7);
  -webkit-filter: blur(5vw);
  -moz-filter: blur(5vw);
  -ms-filter: blur(5vw);
  filter: blur(5vw);
  background: linear-gradient(270deg, #ffc400, #5053ff);
  background-size: 200% 200%;
  animation: animateGlow 2s ease infinite;
}
@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes textColor {
  0% {
    color: #7e0fff;
  }
  50% {
    color: #f8ff6b;
  }
  100% {
    color: #7e0fff;
  }
}
</style>
