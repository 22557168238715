<template>
  <navbar btn-background="bg-gradient-dark" />

  <!-- WELCOME -->
  <section
    class="page-header align-items-start bg-dark hero-section py-7"
    id="hero-section"
    :style="{
      'background-image': 'url(' + BGGrid + ')',
      'background-size': 'cover',
    }"
  >
    <span class="mask opacity-9" style="background-color: #0004aa"></span>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 mt-3 py-5" data-aos="fade-up" data-aos-once="true">
          <!-- Heading -->

          <h1
            class="display-2 text-md-start text-white block-effect"
            style="--td: 0.5s"
          >
            <div class="block-reveal" style="--bc: blue; --d: 0.1s">
              ⚡️ Start building
            </div>
            <div class="block-reveal" style="--bc: #fff; --d: 0.2s">
              on Exa Protocol!
            </div>
          </h1>

          <h4
            class="display-6 text-white my-5"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <mark class="p-2 bg-light text-primary px-4"
              >Trusted by
              <countTo :start-val="0" :end-val="70" :duration="7000"></countTo>+
              projects.</mark
            >
          </h4>

          <!-- Text -->
          <p class="text-md-start text-white text-lg mt-4">
            A distributed file storage and a distributed compute network of
            GPUs.
          </p>

          <!-- Buttons -->
          <div class="text-md-start mt-5">
            <router-link
              to="/authentication/signup"
              class="btn btn-light btn-lg shadow lift me-1"
            >
              Get Started
              <i class="fa fa-arrow-right d-none d-md-inline ms-3"></i>
            </router-link>
            &nbsp;
            <router-link
              to="/rent-machine"
              class="btn btn-lg btn-outline-white lift"
            >
              Rent Your Machine
            </router-link>
          </div>
        </div>

        <div
          class="col-md-6 d-none d-sm-none d-md-block"
          data-aos="zoom-in"
          data-aos-delay="1000"
          data-aos-once="true"
        >
          <div v-if="referredBy" class="p-5 h-100 d-flex">
            <div class="text-center align-self-center mx-auto">
              <div class="card">
                <div
                  class="card-body py-4"
                  style="border: 5px dotted orangered"
                >
                  <Avatar :size="80" variant="beam" :name="referralName" />
                  <h3 class="mt-3">You got referred! 🎉</h3>
                  <p class="text-lg">
                    <b>{{ referralName }}</b> invited you to join Exa Protocol.
                  </p>
                  <hr class="horizontal dark" />

                  <p>
                    You both will earn <b>2500 compute points</b> after you
                    successfully rent your machine!
                  </p>
                  <router-link
                    to="/authentication/signup"
                    class="btn btn-primary"
                    >Sign Up Now!</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <Globe width="700" height="700" v-else />
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- Two services -->
  <section class="py-7 border-bottom bg-white">
    <div class="container">
      <div class="row">
        <div class="col-md-4 mt-4" data-aos="fade-up" data-aos-once="true">
          <h2>A unified decentralized cloud for storage and compute.</h2>
          <p class="my-4">
            With this integration, users can store their data securely and
            scalably on ExaDrive, powered by decentralized storage nodes. Once
            the data is stored, it can be directly accessed by ExaCompute to
            perform advanced computational tasks such as AI model inference,
            machine learning, data analytics, and more.
          </p>
          <h4>ExaCompute 🤝 ExaDrive</h4>
        </div>
        <div class="col-md-4 mt-4">
          <div
            class="card bg-gradient-primary"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div class="card-body">
              <img
                :src="require('@/assets/img/exa-compute.png')"
                alt="ExaDrive Logo"
                class="product-logo"
              />
              <p class="my-4 text-white">
                ExaCompute harnesses a distributed network of CPUs and GPUs,
                making it ideal for resource-intensive tasks such as AI, LLMs,
                machine learning, and large-scale data processing.
              </p>
              <a
                href="https://www.exaprotocol.com/exa-compute"
                target="_blank"
                class="btn btn-light btn-sm d-block"
                >Learn More</a
              >
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-4">
          <div
            class="card bg-gradient-primary"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div class="card-body justify-content-center">
              <img
                :src="require('@/assets/img/exa-drive.png')"
                alt="ExaCompute Logo"
                class="product-logo"
              />
              <p class="my-4 text-white">
                ExaDrive is a decentralized storage solution that redefines how
                data is stored, accessed, and managed. It leverages a network of
                mobile devices and dedicated storage nodes.
              </p>
              <a
                href="https://www.exaprotocol.com/exadrive"
                target="_blank"
                class="btn btn-light btn-sm d-block"
                >Learn More</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Machine Map -->
  <section>
    <div class="container-fluid p-0 m-0">
      <MachineMap />
    </div>
  </section>

  <section class="py-7 bg-white">
    <TierLadder />
  </section>
  <!-- Earnings Calculator -->
  <section class="py-6 bg-light">
    <div class="container">
      <div class="row h-100 mt-4 d-flex justify-content-center">
        <div
          class="col-md-6 col-xs-12 justify-content-center"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <h3>Earn crypto with ExaCompute!</h3>
          <p>
            By renting your unused CPU or GPU bandwidth to Exa Protocol, you can
            earn xCP (Compute Points). Use the Earnings Calculator to give you
            an instant earning estimate based on your system specifications.
          </p>
          <img
            :src="require('@/assets/img/token.png')"
            alt=""
            data-aos="flip-left"
            class="img-fluid d-block mx-auto my-5"
            style="width: 120px"
          />
          <ul>
            <li>
              Quick Estimates: Real-time xCP earning projections based on your
              hardware.
            </li>
            <li>
              Optimize Your Contribution: Find the best setup to maximize your
              earnings.
            </li>
            <li>
              Passive Income: Turn your unused computing bandwidth into actual
              rewards.
            </li>
          </ul>

          <router-link
            to="/rent-machine"
            class="btn btn-md btn-outline-primary lift mt-4"
          >
            Rent Your Machine
          </router-link>
        </div>
        <div class="col-md-6 col-xs-12" data-aos="fade-up" data-aos-once="true">
          <div class="card h-100" data-aos="fade-up" data-aos-once="true">
            <div class="card-body">
              <h3><i class="fa fa-coins"></i> Earnings Calculator</h3>
              <p class="text-sm">
                A modern CPU is required with at least 4 physical cores.
              </p>
              <EarningsCalculator />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- FEATURES -->
  <section
    class="py-7 border-bottom bg-white"
    :style="{
      'background-image': 'url(' + BGFeatures + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'contain',
      'background-position': 'center',
    }"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12 my-4">
          <h2 class="">Exa Protocol Features</h2>
          <p class="">
            Unlock the true power of Decentralized Internet with ExaDrive and
            ExaCompute
          </p>
        </div>
      </div>
      <div class="row text-center">
        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body border-radius-lg">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-bolt"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Web2 Speeds in a DFS</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-0 text-md">
                ExaDrive brings the speed and performance of traditional Web2
                cloud storage to the decentralized world of Web3. Leveraging a
                powerful Content Delivery Network (CDN), ExaDrive ensures that
                your files are quickly served, just like in conventional cloud
                solutions.
              </p>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-4 bg-body border-radius-lg">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-globe"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Host Static Websites</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-0 text-md">
                ExaDrive allows you to host static websites seamlessly on its
                decentralized file system. Your static assets, like HTML, CSS,
                and JavaScript files are served quickly and efficiently to users
                worldwide. Your website is securely distributed across mobile
                nodes, ensuring redundancy and resilience.
              </p>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-4 bg-body border-radius-lg">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-star"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Familiar to Web2</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-0 text-md">
                No need to learn new concepts. With no Web3 jargon, our SDK
                makes file storage and compute access as simple as you're used
                to. Install it, integrate seamlessly, and start using it
                immediately. The files are arranged in a directory structure
                that is similar to Web2 and compute can be accessed via SSH.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body border-radius-lg">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-server"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">VM Access via SSH</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-6 mb-md-0 text-md">
                ExaCompute provides seamless access to decentralized virtual
                machines (VMs), enabling users to run applications, perform
                computations, and process data efficiently. With support for SSH
                access, users can securely manage and customize their VMs.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body border-radius-lg">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-microchip"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Access to GPUs</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-0 text-md">
                Access powerful GPUs with ExaCompute. Ideal for AI, machine
                learning, and other GPU-intensive tasks, ExaCompute provides
                scalable and efficient compute resources within a decentralized
                network.
              </p>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body border-radius-lg">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-code"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Serverless Computing</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-6 mb-md-0 text-md">
                Execute tasks instantly with ExaCompute Omega functions - no
                need to start or manage VMs. Run AI/ML tasks, 3D rendering with
                Blender, and more directly on a decentralized compute network
                for seamless, on-the-fly performance.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body border-radius-lg">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-hexagon-nodes"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Run AI, ML, and LLM Tasks</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-6 mb-md-0 text-md">
                Run AI, machine learning, and large language model (LLM) tasks
                seamlessly on decentralized virtual machines (VMs) with
                ExaCompute. Leverage powerful distributed GPU and CPU resources
                to handle complex computations and training workloads.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-3 mt-2 mb-3"
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-once="true"
        >
          <div class="card h-100 shadow-lg mb-5 bg-body border-radius-lg">
            <div class="card-body">
              <!-- Icon -->
              <div class="mb-3 text-center">
                <h4>
                  <span class="fa fa-sitemap"></span>
                </h4>
              </div>

              <!-- Heading -->
              <h5 class="font-weight-bolder my-4">Built in HTTP Tunnels</h5>

              <!-- Text -->
              <p class="text-body-secondary mb-6 mb-md-0 text-md">
                Access HTTP Tunnels with ExaCompute to host web servers like
                NGINX, Apache, and more effortlessly. Enable secure, scalable
                connections, and deploy your applications directly from
                decentralized virtual machines with ease.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- How It Works -->
  <section class="py-6 border-bottom bg-light">
    <div class="container">
      <div class="row">
        <div class="col-md-12 my-4">
          <h2 class="">Exa Protocol Architecture</h2>
          <p class="">
            This architecture is representational only and is a simplified view.
          </p>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-12 py-6 mb-4"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <img
            :src="require('@/assets/img/complete_arc_trans_dark.svg')"
            alt=""
            class="img-fluid"
          />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <ul>
            <li>
              Exa Protocol = Decentralized Storage + Decentralized Compute
            </li>
            <li>
              ExaDrive uses decentralized mobile nodes and a CDN for fast file
              delivery. Frequently accessed files are cached across nodes for
              low-latency access, while your data is securely stored in
              distributed clusters. True ownership lies with you 24x7
            </li>

            <li>
              Integrated with ExaCompute, this unified ecosystem lets users
              store data and run compute tasks directly on the decentralized
              network. From AI/ML models to 3D rendering, ExaCompute harnesses
              distributed CPUs and GPUs for high-performance, scalable
              computation.
            </li>
            <li>
              ExaDrive and ExaCompute together deliver a robust, secure, and
              efficient platform for decentralized storage and compute.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <!-- FAQ -->
  <section class="bg-white">
    <div class="container py-8">
      <div class="row">
        <div class="col-md-4" data-aos="fade-up" data-aos-once="true">
          <h2>Why Exa Protocol?</h2>

          <p class="text">
            ExaDrive and ExaCompute offer a cutting-edge Web3 platform for
            decentralized storage and compute. Deeply integrated, they deliver a
            seamless UX that just works. Leveraging mobile devices and
            distributed resources, they provide an efficient, flexible solution
            that meets developers' need for performance and ease.
          </p>
          <p class="text">
            By combining Web2 cloud storage speeds with the scalability of Web3
            DFS and decentralized compute, ExaDrive and ExaCompute are the
            ultimate choice for developers seeking high performance, simplicity
            and scalability within a decentralized ecosystem.
          </p>
        </div>
        <div
          class="col-md-8"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <Accordion :value="['0']" multiple>
            <AccordionPanel value="0">
              <AccordionHeader>Fastest Web3 DFS</AccordionHeader>
              <AccordionContent>
                <p class="m-0 text-sm">
                  ExaDrive delivers Web2 cloud storage speeds in a Web3 DFS by
                  utilizing a Content Delivery Network (CDN) to quickly serve
                  files, just like traditional cloud solutions. Files are
                  securely and permanently stored on mobile devices, ensuring
                  both speed and security. With optimized data transfer, low
                  latency, and scalable architecture, ExaDrive provides
                  unmatched performance in decentralized file storage.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="1">
              <AccordionHeader>Seamless File Management</AccordionHeader>
              <AccordionContent>
                <p class="m-0 text-sm">
                  ExaDrive provides an intuitive interface to save, organize,
                  and track your data with ease. Features like file versioning,
                  sharing, and real-time updates make it as simple to use as
                  Web2 storage platforms, enabling developers to integrate
                  decentralized storage seamlessly into their workflows.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="2">
              <AccordionHeader>Static Website Hosting</AccordionHeader>
              <AccordionContent>
                <p class="m-0 text-sm">
                  Host your static websites directly on the ExaDrive
                  decentralized network, ensuring high availability and faster
                  content delivery across the globe. This feature eliminates
                  dependency on centralized web hosting providers while
                  maintaining reliability and scalability for your web
                  applications.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="3">
              <AccordionHeader>Flexible Virtual Machines</AccordionHeader>
              <AccordionContent>
                <p class="m-0 text-sm">
                  ExaCompute allows you to deploy highly customizable virtual
                  machines tailored to your needs. You can choose any operating
                  system, such as Ubuntu or CentOS, and install necessary
                  software like Node.js, Python, or Jupyter Notebooks. This
                  flexibility makes ExaCompute ideal for diverse use cases,
                  including AI/ML workloads, application hosting, and software
                  testing.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="4">
              <AccordionHeader
                >Instant Code Execution with Omega</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  Omega lets you execute code directly without the need to set
                  up or manage VMs. Perfect for tasks like AI/ML computations,
                  3D rendering, and quick testing, Omega enables on-the-fly
                  execution in a decentralized environment, saving time and
                  reducing complexity.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="5">
              <AccordionHeader
                >Distributed GPU and CPU Resources</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  ExaCompute taps into a network of distributed CPUs and GPUs to
                  provide high-performance compute resources. This allows you to
                  train AI models, run large-scale simulations, or perform
                  GPU-intensive tasks like rendering and video processing
                  efficiently. By leveraging decentralized compute power,
                  ExaCompute offers scalability and cost savings over
                  traditional cloud solutions.
                </p>
              </AccordionContent>
            </AccordionPanel>
          </Accordion>
        </div>
        <div class="col-lg-12 mt-6 d-none d-sm-none d-md-block">
          <div class="card">
            <div class="card-header mb-0">
              <h5 class="mb-0">Built to run anything...</h5>
            </div>
            <div class="card-body mt-0 pt-0 pb-0">
              <img
                :src="require('@/assets/img/any_soft_compute.png')"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- CTA -->
  <section
    class="py-7 py-md-8 bg-gradient-dark"
    :style="{
      'background-image': 'url(' + BGCTA + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
      'background-position': 'bottom',
    }"
  >
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-6 col-lg-6 mt-4">
          <!-- Heading -->
          <h1 class="display-4 text-white">
            Web3 Project? <br />Use Exa Protocol!
          </h1>
          <p class="text-white text-lg mt-3">
            The future of internet is decentralized infra. Get started today
            with decentralized storage using Exa Protocol!
          </p>

          <!-- Button -->
          <router-link
            to="/authentication/signup"
            class="btn btn-primary btn-lg lift mt-4"
          >
            Get Started &nbsp; <i class="fa fa-arrow-right"></i>
          </router-link>
        </div>

        <div
          class="col-md-6 col-lg-6 mt-4"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div class="card">
            <div class="card-body">
              <h3>Apply for grants!</h3>
              <p class="text-md">
                Become a part our Accelerate program and get upto 1000 $XAP to
                build your Web3 apps using Exa Protocol!
              </p>
              <div class="text-center">
                <a
                  class="btn btn-primary btn-lg my-4"
                  href="https://forms.gle/KXmK68rQBfdpWKX6A"
                  target="_blank"
                  >Apply Now</a
                >
              </div>

              <h6 class="mt-3">About Accelerate</h6>
              <p>
                Accelerate program is for Web3 projects to build their apps on a
                decentralized cloud infrastructure.
              </p>
              <p class="text-xs mt-4">
                *Accelerate program is for a limited period only.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- FOOTER -->
  <GlobalFooter />
</template>

<script>
import Navbar from "@/components/PageLayout/Navbar.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";
import { mapMutations } from "vuex";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import DefaultCounterCard from "../../examples/Cards/DefaultCounterCard.vue";
import Globe from "../../examples/Globe.vue";
import Brands from "./componentrs/Brands.vue";
import BGGrid from "@/assets/img/bg-grid.svg";
import BGCube from "@/assets/img/bg-cube.svg";
import BGCTA from "@/assets/img/bg-cta.svg";
import BGFeatures from "@/assets/img/bg-features.png";
import countTo from "vue-count-to/src";
import Avatar from "vue-boring-avatars";
import EarningsCalculator from "../rentMachine/components/EarningsCalculator.vue";
import MachineMap from "../rentMachine/components/MachineMap.vue";
import TierLadder from "./componentrs/TierLadder.vue";

export default {
  name: "HomePage",
  components: {
    Navbar,
    GlobalFooter,
    Accordion,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
    DefaultCounterCard,
    Globe,
    Brands,
    countTo,
    Avatar,
    EarningsCalculator,
    MachineMap,
    TierLadder,
  },
  data() {
    return {
      BGGrid: BGGrid,
      BGCube: BGCube,
      BGCTA: BGCTA,
      BGFeatures: BGFeatures,
      referredBy: localStorage.getItem("referredBy"),
      referralName: localStorage.getItem("referralName"),
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {},
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>

<style>
.footer-logo {
  width: 60px;
}
.hero-logo {
  width: 80px;
}

.exa-powby {
  width: 160px;
  height: auto;
  position: fixed;
  bottom: 40px;
  right: 0px;
  z-index: 100000;
}
.exa-powby img {
  width: 100%;
  border-radius: 10px 0px 0px 10px;
}
.hero-section {
  /* min-height: 100vh; */
}

.product-logo {
  width: 100px;
  display: block;
  margin: 10px auto;
}

/* block-$ */
.block-effect {
  font-size: calc(8px + 6vw);
}

.block-reveal {
  --t: calc(var(--td) + var(--d));
  color: transparent;
  padding: 4px;
  position: relative;
  overflow: hidden;
  animation: revealBlock 0s var(--t) forwards;
}

.block-reveal::after {
  content: "";

  width: 0%;
  height: 100%;
  padding-bottom: 4px;

  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  background: var(--bc);
  animation: revealingIn var(--td) var(--d) forwards,
    revealingOut var(--td) var(--t) forwards;
}

/* animations */
@keyframes revealBlock {
  100% {
    color: #fff;
  }
}

@keyframes revealingIn {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes revealingOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}
</style>
