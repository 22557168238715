<template>
  <footer class="py-5 mt-5 position-relative overflow-hidden">
    <!-- Background elements removed for subtlety -->

    <div class="container position-relative">
      <div class="row py-3">
        <!-- Company Info -->
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h4>Exa Protocol</h4>
          <p class="mb-3 footer-text">
            Decentralized cloud powered by a mobile-first DFS and GPU powered
            computing nodes. Building the future of Web3 infrastructure.
          </p>
          <p class="text-sm copyright">
            © {{ new Date().getFullYear() }} ExaProtocol.com
          </p>

          <!-- Social Media with subtle style -->
          <div class="mt-3">
            <div class="d-flex social-icons">
              <a
                href="https://t.me/exaofficial"
                target="_blank"
                class="social-icon me-3"
                title="Telegram"
              >
                <i class="fa fa-telegram"></i>
              </a>
              <a
                href="https://twitter.com/ExaProtocol"
                target="_blank"
                class="social-icon me-3"
                title="Twitter"
              >
                <i class="fa fa-twitter"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UC1p9HLM8np7KDstZdxvMpJw"
                target="_blank"
                class="social-icon me-3"
                title="YouTube"
              >
                <i class="fa fa-youtube"></i>
              </a>
              <a
                href="https://github.com/Exa-Protocol"
                target="_blank"
                class="social-icon me-3"
                title="GitHub"
              >
                <i class="fa fa-github"></i>
              </a>
            </div>
          </div>
        </div>

        <!-- Products Links -->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5 class="web3-heading text-uppercase fw-bold mb-2">Products</h5>
          <ul class="list-unstyled footer-links">
            <li class="mb-2">
              <a href="https://www.exaprotocol.com/exa-compute" target="_blank">
                <i class="fa fa-cube me-1"></i>ExaCompute
              </a>
            </li>
            <li class="mb-2">
              <a href="https://www.exaprotocol.com/exadrive" target="_blank">
                <i class="fa fa-database me-1"></i>ExaDrive
              </a>
            </li>
            <li class="mb-2">
              <a href="https://www.exaprotocol.com/token" target="_blank">
                <i class="fa fa-coins me-1"></i>Token
              </a>
            </li>
            <li class="mb-2">
              <router-link to="/rent-machine">
                <i class="fa fa-server me-1"></i>Rent Compute
              </router-link>
            </li>
            <li class="mb-2">
              <a
                href="https://exa-drive.gitbook.io/exa-protocol-docs"
                target="_blank"
              >
                <i class="fa fa-book me-1"></i>Documentation
              </a>
            </li>
          </ul>
        </div>

        <!-- Desktop Apps -->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5 class="web3-heading text-uppercase fw-bold mb-2">Desktop Apps</h5>
          <ul class="list-unstyled footer-links">
            <li class="mb-2">
              <a href="https://release.exadrivecdn.com/" target="_blank">
                <i class="fa fa-apple me-1"></i>MacOS (ARM)
              </a>
            </li>
            <li class="mb-2">
              <a href="https://release.exadrivecdn.com/" target="_blank">
                <i class="fa fa-windows me-1"></i>Windows (x64)
              </a>
            </li>
            <li class="mb-2">
              <a href="https://release.exadrivecdn.com/" target="_blank">
                <i class="fa fa-linux me-1"></i>Linux CLI
              </a>
            </li>
          </ul>
        </div>

        <!-- Company Links -->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5 class="web3-heading text-uppercase fw-bold mb-2">Company</h5>
          <ul class="list-unstyled footer-links">
            <li class="mb-2">
              <a href="https://www.exaprotocol.com" target="_blank">
                <i class="fa fa-home me-1"></i>Main Site
              </a>
            </li>
            <li class="mb-2">
              <a href="https://www.exaprotocol.com/research" target="_blank">
                <i class="fa fa-flask me-1"></i>Research
              </a>
            </li>
            <li class="mb-2">
              <a href="https://www.exaprotocol.com/contact" target="_blank">
                <i class="fa fa-envelope me-1"></i>Contact
              </a>
            </li>
            <li class="mb-2">
              <a href="https://www.exaprotocol.com/blog" target="_blank">
                <i class="fa fa-rss me-1"></i>Blog
              </a>
            </li>
            <li class="mb-2">
              <router-link to="/privacy">
                <i class="fa fa-shield me-1"></i>Privacy Policy
              </router-link>
            </li>
            <li class="mb-2">
              <router-link to="/tos">
                <i class="fa fa-gavel me-1"></i>Terms of Service
              </router-link>
            </li>
          </ul>
        </div>

        <!-- Grants Program with subtle styling -->
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <div class="grants-program p-3 rounded">
            <h5 class="fw-bold mb-2 primary-text">Accelerate Grants Program</h5>
            <p class="mb-2 footer-text">
              Build with Exa Protocol and get up to 1000 $XAP through our
              Accelerate program for Web3 projects. Launch your decentralized
              applications with our support.
            </p>
            <a
              href="https://forms.gle/KXmK68rQBfdpWKX6A"
              target="_blank"
              class="btn-web3 mt-2"
            >
              Apply for Grants <i class="fa fa-arrow-right ms-1"></i>
            </a>
          </div>
        </div>
      </div>

      <!-- Bottom bar -->
      <div class="bottom-bar mt-3 pt-2">
        <div class="row">
          <div class="col-md-8">
            <p class="text-xs mb-0 footer-text-alt">
              <i class="fa fa-info-circle me-1"></i> Exa Protocol Management
              Console is currently open beta. Expect ephemeral data storage, and
              CDN downtime.
            </p>
          </div>
          <div class="col-md-4 text-md-end">
            <p class="text-xs mb-0">
              <a href="mailto:support@exaprotocol.com" class="primary-text"
                >support@exaprotocol.com</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "GlobalFooter",
};
</script>

<style scoped>
/* Subtle footer styling */
footer {
  color: #6b7280;
}

/* Remove blockchain nodes animation for subtlety */
.blockchain-nodes {
  display: none;
}

/* Logo styling - more subtle */
.footer-logo {
  width: 35px;
  height: 35px;
  opacity: 0.9;
  transition: all 0.2s ease;
}

.footer-logo:hover {
  opacity: 1;
}

/* Primary text color - more subtle */
.primary-text {
  color: #6172d6;
  font-weight: 500;
}

/* Web3 themed headings - more subtle */
.web3-heading {
  color: #4b5563;
  position: relative;
  font-size: 0.95rem;
}

.web3-heading:after {
  content: "";
  display: block;
  width: 30px;
  height: 2px;
  background: #6172d6;
  margin-top: 6px;
  border-radius: 2px;
}

/* Footer text styling - more subtle */
.footer-text {
  color: #6b7280;
  font-weight: 400;
  font-size: 0.9rem;
}

.footer-text-alt {
  color: #9ca3af;
  font-weight: 400;
}

.footer-text-muted {
  color: #9ca3af;
}

/* Copyright text */
.copyright {
  color: #9ca3af;
}

/* Social icons - more subtle */
.social-icon {
  color: #6b7280;
  font-size: 1rem;
  transition: all 0.2s ease;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(97, 114, 214, 0.08);
}

.social-icon:hover {
  color: #6172d6;
  background: rgba(97, 114, 214, 0.15);
}

/* Link styling - more subtle */
.footer-links a {
  color: #6b7280;
  text-decoration: none;
  transition: all 0.2s ease;
  display: inline-block;
  padding: 1px 0;
  font-weight: 400;
  font-size: 0.9rem;
}

.footer-links a:hover {
  color: #6172d6;
  transform: translateX(2px);
}

/* Grants program card - more subtle */
.grants-program {
  background: rgba(97, 114, 214, 0.03);
  border: 1px solid rgba(97, 114, 214, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
  transition: all 0.2s ease;
}

.grants-program:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}

.grants-program .footer-text {
  color: #6b7280;
  font-size: 0.85rem;
}

.grants-program .footer-text-muted {
  color: #9ca3af;
}

/* Web3 style button - more subtle */
.btn-web3 {
  background: #6172d6;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.85rem;
  display: inline-block;
  transition: all 0.2s ease;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(97, 114, 214, 0.2);
}

.btn-web3:hover {
  background: #5161c5;
  box-shadow: 0 2px 6px rgba(97, 114, 214, 0.3);
  color: white;
}

/* Bottom bar styling - more subtle */
.bottom-bar {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
</style>
