<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-lg border"
    style="margin-top: 1rem"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="py-1 container-fluid d-flex justify-content-end">
      <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none me-4">
        <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">
          <div class="sidenav-toggler-inner">
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
          </div>
        </a>
      </div>
      <!-- <breadcrumbs
        :current-directory="currentDirectory"
        :current-page="currentRouteName"
        :text-white="textWhite"
      /> -->

      <div id="navbar" class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="p-0 nav-link text-body"
              @click="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
          <li class="mx-2 nav-item d-flex align-items-center">
            <a
              href="#"
              class="text-sm text-dark bg-light p-2 px-3"
              data-bs-toggle="modal"
              data-bs-target="#referralViewModal"
              style="border-radius: 10px"
              ><i class="fa fa-gift"></i>&nbsp; Get 2500 xCP!
            </a>
          </li>
          <li class="mx-2 nav-item d-flex align-items-center">
            <a href="#" class="text-sm text-bold" @click="logout()"
              ><i class="fa fa-person-hiking"></i> Log Out
            </a>
          </li>
          <li class="mx-2 nav-item d-flex align-items-center">
            <Avatar :size="30" variant="beam" :name="userId ? userId : 'Exa'" />
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- View Referral Link -->
  <div
    class="modal"
    id="referralViewModal"
    aria-labelledby="viewReferralLinLabel"
    aria-hidden="true"
    style="background-color: rgba(0, 0, 255, 0.2)"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="">Your Referral Link</h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-close"></i>
          </button>
        </div>
        <div class="modal-body p-5 text-center">
          <div class="row">
            <div class="col-lg-12">
              <img
                :src="require('@/assets/img/gift.png')"
                alt=""
                class="img-fluid d-block mx-auto"
                style="width: 100px"
              />
            </div>
            <hr class="horizontal dark my-4" />
            <div class="col-lg-12">
              <template v-if="referralLink">
                <code style="font-size: 12px">{{ referralLink }}</code>
                <button
                  class="btn btn-outline-primary btn-sm d-block mx-auto mt-4"
                  @click="copyText(referralLink)"
                >
                  <i class="fa fa-copy"></i> Copy link
                </button>
                <hr class="horizontal dark my-4" />

                <p class="mt-4">
                  You and your referral will earn
                  <b>2500 Exa Compute Points (xCP)</b> after your referral
                  successfuly rents their machine! 🎉 <br /><br />
                  Additionally, you also get
                  <b>0.5% of their earned xCP</b>
                  perpetually!
                </p>
              </template>
              <p v-else class="text-center text-danger">
                <i class="fa fa-warning"></i> Sorry, referrals have been
                disabled for your account.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions, mapState } from "vuex";
import Avatar from "vue-boring-avatars";
import axios from "axios";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
    Avatar,
  },
  props: {
    minNav: {
      type: Function,
      default: () => {},
    },
    textWhite: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.getReferral();
  },
  data() {
    return {
      showMenu: false,
      userId: localStorage.getItem("userID"),
      referralLink: null,
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      return this.$route.name;
      // let dir = this.$route.path.split("/")[1];
      // return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
  },
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    logout() {
      localStorage.removeItem("jwt");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("expiresIn");
      localStorage.removeItem("userID");
      localStorage.removeItem("userName");
      this.$router.push("/authentication/signin");
    },

    getReferral() {
      axios
        .get(
          this.$store.getters["getServerURL"] + "/referral/getReferralCode",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.referralLink = response.data.referralLink;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    copyText(text) {
      var context = this;
      navigator.clipboard.writeText(text).then(
        function () {
          context.$toast.add({
            severity: "success",
            summary: "Copied",
            detail: "Copied to clipboard:\n" + text,
            life: 5000,
          });
        },
        function (err) {
          context.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to copy CDN URL:\n" + text,
          });
        }
      );
    },
  },
};
</script>
