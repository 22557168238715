<template>
  <navbar btn-background="bg-gradient-dark" />
  <section
    class="pt-5 page-header align-items-start min-vh-40 pb-10"
    :style="{
      'background-image': 'url(' + BGGrid + ')',
      'background-size': 'cover',
      'background-position': 'top',
    }"
  >
    <span class="mask opacity-10" style="background-color: darkblue"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h1 class="mt-2 pt-5 mb-2 text-white">🙏 Welcome Back!</h1>
          <p class="text-white">
            Sign in with your Google Account or Email - normie friendly login!
          </p>

          <p
            v-if="this.$route.query.register == 'true'"
            class="text-xs text-white alert alert-success font-weight-bold text-center mt-4"
          >
            Account creation successful! <br />Please check your email for
            activation link!
          </p>

          <p
            v-if="this.$route.query.passwordChange == 'true'"
            class="text-xs text-white alert alert-success font-weight-bold"
          >
            Password changed successfully. <br />You can now login with the new
            password.
          </p>
        </div>
      </div>
    </div>
  </section>
  <div class="container">
    <div class="row mt-lg-n9 mt-md-n9 mt-n10 justify-content-center">
      <div class="col-md-4">
        <div class="card z-index-0">
          <div class="card-body">
            <div class="my-2 d-flex justify-content-center">
              <div
                id="g_id_onload"
                :data-client_id="googleClientId"
                data-context="signin"
                data-ux_mode="popup"
                data-itp_support="true"
                data-cancel_on_tap_outside="false"
                data-skip_prompt_cookie="google-signin-skipped"
                data-callback="handleCredentialResponse"
                data-auto_prompt="false"
              ></div>
              <div
                class="g_id_signin"
                data-type="standard"
                data-shape="rectangular"
                data-theme="outline"
                data-text="signin_with"
                data-size="large"
                data-logo_alignment="center"
              ></div>
            </div>

            <div class="text-center position-relative my-3">
              <p
                class="px-3 text-sm bg-white font-weight-bold text-secondary text-border d-inline z-index-2"
              >
                or
              </p>
            </div>
            <p class="text-center text-sm text-bold text-dark">
              Sign in with your email and password
            </p>
            <form role="form" class="text-start" @submit.prevent="signIn()">
              <div class="mb-3">
                <input
                  class="form-control"
                  id="email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="username"
                  required
                  v-model="email"
                />
              </div>

              <div class="mb-3">
                <input
                  class="form-control"
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  maxlength="32"
                  minlength="8"
                  required
                  placeholder="Password"
                  v-model="password"
                />
              </div>

              <div>
                <ul
                  v-if="validationMessages.length > 0"
                  class="text-danger text-sm text-left mt-4"
                >
                  <li v-for="message in validationMessages" :key="message">
                    {{ message }}
                  </li>
                </ul>

                <div class="d-flex justify-content-center mb-3 mt-4">
                  <RecaptchaV2
                    @widget-id="handleWidgetId"
                    @error-callback="handleErrorCalback"
                    @expired-callback="handleExpiredCallback"
                    @load-callback="handleLoadCallback"
                  />
                </div>

                <button
                  class="mb-2 btn btn-primary d-block w-100"
                  type="submit"
                >
                  Sign in
                </button>
              </div>
            </form>

            <div class="text-center">
              <router-link
                :to="'/authentication/signup'"
                class="btn btn-link text-dark w-100 mt-2 mb-2"
                full-width
                >New User? Sign up!
              </router-link>

              <hr class="horizontal dark" />
              <p class="">
                <router-link
                  :to="'/authentication/reset'"
                  class="p-0 m-0 text-xs"
                  full-width
                  >Forgot your password?
                </router-link>
                |
                <router-link
                  class="m-0p-0 text-xs"
                  to="/authentication/resend-activation"
                  >Resend Activation Mail</router-link
                >
              </p>
              <p class="p-0 m-0 text-xs">
                <a
                  class="m-0"
                  href="https://api.elasticemail.com/contact/subscribe?version=2&pID=870dc722-835b-45b7-9040-0975dc0f30f5"
                  target="_blank"
                  >Not Receiving emails? Resubscribe.</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card h-100">
          <div class="card-body">
            <div
              class="d-flex h-100 border-radius-lg"
              :style="{
                'background-image': !referredBy
                  ? 'url(' + require('@/assets/img/singin-ghibli.png') + ')'
                  : 'none',
                'background-size': 'cover',
                'background-position': 'left',
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast />
  <GlobalFooter />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
import axios from "axios";
import { RecaptchaV2, useRecaptcha } from "vue3-recaptcha-v2";
const { handleReset } = useRecaptcha();
import BGGrid from "@/assets/img/bg-grid.svg";

import Toast from "primevue/toast";

import { mapMutations } from "vuex";
import config from "@/config.js";

export default {
  name: "SigninBasic",
  components: {
    Navbar,
    GlobalFooter,
    SoftInput,
    SoftSwitch,
    SoftButton,
    RecaptchaV2,
    Toast,
  },
  data() {
    return {
      BGGrid: BGGrid,
      email: "",
      password: "",
      emailValidationError: false,
      passwordValidationError: false,
      validationMessages: [],
      captchaResponse: "",
      captchaResponseMessage: "",
      widgetID: "",
      googleClientId: config.googleClientId,
      googleScriptLoaded: false,
    };
  },
  mounted() {
    if (localStorage.getItem("jwt")) {
      this.$router.push("/dashboard");
      return;
    }

    if (this.$route.query.register == "true") {
      this.$confetti.start();
      setTimeout(() => {
        this.$confetti.stop();
      }, 2000);
    }

    if (this.$route.query.forgotPass == "true") {
      this.$toast.add({
        severity: "success",
        summary: "Check your email!",
        detail:
          "If the email was found in the database, a reset password link will be sent.",
      });
    }

    // Set up global callback for Google Sign-In
    window.handleCredentialResponse = this.handleCredentialResponse;

    // Initialize Google Sign-In without loading the script (already in index.html)
    this.initializeGoogleSignIn();
  },
  activated() {
    // This lifecycle hook triggers when a component is re-activated via vue-router
    // Always reinitialize when the component is re-activated
    window.handleCredentialResponse = this.handleCredentialResponse;
    this.initializeGoogleSignIn();
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    handleWidgetId(widgetId) {
      // console.log("Widget ID: ", widgetId);
      this.widgetID = widgetId;
    },
    handleErrorCalback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleExpiredCallback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleLoadCallback(response) {
      // console.log("Load callback", response);
      this.captchaResponseMessage = response;
      this.captchaResponse = true;
    },
    validateEmail() {
      var regex = /^[a-zA-Z0-9_.+\-]+[\x40][a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.email)) {
        console.log("Email is invalid.");
        this.validationMessages.push("Email is invalid.");
        this.emailValidationError = true;
      } else {
        this.emailValidationError = false;
      }
    },
    validatePassword() {
      var passwordVal = true;
      if (this.password.length < 8 || this.password.length > 32)
        passwordVal = false;

      if (!passwordVal) {
        this.validationMessages.push(
          "Password is invalid. \nPassword must be between 8 and 32 characters."
        );
        this.passwordValidationError = true;
      } else {
        this.passwordValidationError = false;
      }
    },
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    signIn() {
      //reset errors
      this.validationMessages = [];
      this.emailValidationError = false;
      this.passwordValidationError = false;

      this.validateEmail();
      this.validatePassword();

      if (this.emailValidationError || this.passwordValidationError) {
        return;
      }

      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/auth/signin",
        data: {
          email: this.email,
          password: this.password,
          gRecaptchaResponse: this.captchaResponseMessage,
        },
      })
        .then((response) => {
          // console.log(response);
          var user = response.data;
          localStorage.setItem("jwt", user.token);
          localStorage.setItem("loggedIn", "true");
          localStorage.setItem("expiresIn", user.expiresIn);
          localStorage.setItem("userID", user.user.userID);
          localStorage.setItem("userName", user.user.name);
          localStorage.setItem("role", user.user.role);
          localStorage.removeItem("referredBy");
          localStorage.removeItem("referralName");
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          // console.log(error);
          handleReset(this.widgetID);
          this.captchaResponseMessage = "";
          this.captchaResponse = false;
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: error.response.data.message,
            life: 3000,
          });
        });
    },

    async handleCredentialResponse(response) {
      try {
        // If the response came from a popup and has credential property
        const idToken = response.credential;

        const result = await axios({
          method: "post",
          url: this.$store.getters["getServerURL"] + "/auth/signin/google",
          data: { accessToken: idToken },
        });

        const user = result.data;
        if (user.loggedIn) {
          localStorage.setItem("jwt", user.token);
          localStorage.setItem("loggedIn", "true");
          localStorage.setItem("expiresIn", user.expiresIn || "30d");
          localStorage.setItem("userID", user.user.userID);
          localStorage.setItem("userName", user.user.name);
          localStorage.setItem("role", user.user.role);
          localStorage.removeItem("referredBy");
          localStorage.removeItem("referralName");
          this.$router.push("/dashboard");
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Google Sign-In Failed",
            detail: user.message || "Authentication failed. Please try again.",
            life: 3000,
          });
        }
      } catch (error) {
        console.error("Google Sign-In error:", error);
        // If we have COOP issues, provide a fallback
        if (error.message && error.message.includes("Cross-Origin")) {
          this.$toast.add({
            severity: "warning",
            summary: "Authentication Issue",
            detail:
              "Please try using the regular email sign-in method instead.",
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Google Sign-In Error",
            detail:
              error.response?.data?.message ||
              "An error occurred. Please try again.",
            life: 3000,
          });
        }
      }
    },

    signInWithGoogle() {
      window.location.href =
        this.$store.getters["getServerURL"] + "/auth/signin/google";
    },

    initializeGoogleSignIn() {
      // Wait a bit for the Google API to be ready
      setTimeout(() => {
        if (
          !window.google ||
          !window.google.accounts ||
          !window.google.accounts.id
        ) {
          console.log("Google Sign-In API not yet available, waiting...");
          setTimeout(() => this.initializeGoogleSignIn(), 200); // Retry after a delay
          return;
        }

        try {
          window.google.accounts.id.initialize({
            client_id: this.googleClientId,
            callback: this.handleCredentialResponse,
            context: "signin",
            ux_mode: "popup",
            itp_support: true,
            cancel_on_tap_outside: false,
            use_fedcm_for_prompt: true,
          });

          // Ensure the button element exists before trying to render
          const signInButton = document.querySelector(".g_id_signin");
          if (signInButton) {
            window.google.accounts.id.renderButton(signInButton, {
              type: "standard",
              shape: "rectangular",
              theme: "outline",
              text: "signin_with",
              size: "large",
              logo_alignment: "center",
            });
            console.log("Google Sign-In button rendered successfully");
            this.googleScriptLoaded = true;
          } else {
            console.error("Google Sign-In button container not found");
          }
        } catch (error) {
          console.error("Error initializing Google Sign-In:", error);
          this.$toast.add({
            severity: "error",
            summary: "Authentication Error",
            detail:
              "Could not initialize Google authentication. Please try using email login.",
            life: 5000,
          });
        }
      }, 100);
    },
  },
};
</script>

<style>
.signup-anim {
  height: 90%;
  width: 100%;
}
</style>
