<template>
  <div class="container">
    <h3 class="">🎁 Earn xCP for being an early user of Exa Protocol!</h3>
    <p class="">
      Secure your spot in our exclusive rewards program: Be among the first 500
      users to leap into Tier 5 with top bonuses, sign up before 1,000 users to
      start from Tier 4, join before 2,500 for Tier 3, and before 5,000 for Tier
      2 benefits – all subsequent signups fall into Tier 1. Act fast to unlock
      enhanced earning rewards, exclusive perks, and a head start on your
      success journey!
    </p>
    <div class="row">
      <div
        class="timeline-horizontal d-flex justify-content-between position-relative mt-4"
      >
        <!-- Loop over computed updatedTiers which now includes isCurrent -->
        <div
          v-for="(tier, index) in updatedTiers"
          :key="index"
          class="timeline-item-horizontal text-center"
          :class="{
            'current-offer': tier.isCurrent,
            'glow-box': tier.isCurrent,
            'disabled-offer': tier.tier > currentTier,
            'upcoming-offer': tier.tier < currentTier,
          }"
        >
          <div class="card tier-card">
            <!-- Stamp appears only for tiers above the current tier -->
            <div class="stamp is-nope" v-if="tier.tier > currentTier">
              You Missed It!
            </div>

            <!-- Ribbon for current tier -->
            <div class="ribbon-sm bg-primary" v-if="tier.isCurrent">
              Current Epoch
            </div>
            <!-- Ribbon for upcoming tiers (below current tier) -->
            <div class="ribbon-sm bg-dark" v-if="tier.tier == currentTier - 1">
              Next Epoch
            </div>

            <div class="card-body mt-4">
              <div class="row mb-3">
                <span class="badge bg-light text-dark">{{
                  tier.conditionText
                }}</span>

                <img
                  :src="'images/badges/tier' + tier.tier + '.png'"
                  alt=""
                  class="img-fluid d-block mx-auto mt-4"
                  style="width: 120px"
                  v-tooltip.top="{
                    value: 'Tier ' + tier.tier,
                    autoHide: false,
                  }"
                />
                <h5 class="card-title mb-0">
                  {{ tier.name }}
                </h5>
                <p class="card-text text-secondary text-sm mt-2">
                  {{ tier.description }}
                </p>
              </div>

              <!-- Logarithmic progress bar -->
              <div class="progress mt-4">
                <div
                  class="progress-bar bg-primary progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  :style="{ width: getProgress(tier) + '%' }"
                  :aria-valuenow="getProgress(tier)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p class="text-xs m-0 p-0 mt-2 text-center">
                {{ currentTier == tier.tier ? "Progress..." : "Not Started" }}
              </p>
            </div>
            <div class="card-footer p-0 m-0">
              <div
                class="bg-warning text-dark p-2"
                v-tooltip.left="{
                  value: 'Your account will be credited with this amount.',
                  autoHide: false,
                }"
              >
                <p class="p-0 m-0 text-xs fw-bolder">
                  Credit: {{ tier.pointsEarned.toLocaleString() }} xCP <br />
                </p>
              </div>
              <div
                class="bg-light text-dark p-2"
                v-tooltip.left="{
                  value:
                    'You earn xCP by providing compute. Get higher earning bonus for signing up early!',
                  autoHide: false,
                }"
              >
                <p class="p-0 m-0 text-xs fw-bolder">
                  Earning Bonus: +{{ tier.earningBenefit }}%
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12">
          <router-link
            to="/authentication/signup"
            class="btn btn-primary btn-lg mb-0 mb-2"
            >Sign Up Now!</router-link
          >
          <p class="m-0 p-0">⏰ Secure your spot before the window ends!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserTierTimeline",
  data() {
    return {
      userCount: 0,
      tiers: [
        {
          tier: 5,
          name: "Pioneer",
          conditionText: "First 500 Users",
          description:
            "If you’re one of the first 500 users—you’ll start from Tier 5.",
          earningBenefit: 66.67,
          pointsEarned: 328125,
          minUsers: 1,
          maxUsers: 500,
        },
        {
          tier: 4,
          name: "Voyager",
          conditionText: "Before 1,000 Users",
          description:
            "Sign up before we hit 1,000 users to start from Tier 4.",
          earningBenefit: 50,
          pointsEarned: 164062,
          minUsers: 501,
          maxUsers: 1000,
        },
        {
          tier: 3,
          name: "Pathfinder",
          conditionText: "Before 2,500 Users",
          description:
            "Sign up before we hit 2,500 users to start from Tier 3.",
          earningBenefit: 33.33,
          pointsEarned: 82032,
          minUsers: 1001,
          maxUsers: 2500,
        },
        {
          tier: 2,
          name: "Seeker",
          conditionText: "Before 5,000 Users",
          description:
            "Sign up before we hit 5,000 users to start from Tier 2.",
          earningBenefit: 16.67,
          pointsEarned: 17280,
          minUsers: 2501,
          maxUsers: 5000,
        },
        {
          tier: 1,
          name: "Scout",
          conditionText: "Everyone Else",
          description:
            "Anyone who signs up after 5,000 users will be placed in Tier 1.",
          earningBenefit: 0,
          pointsEarned: 0,
          minUsers: 5001,
          maxUsers: 100000, // Arbitrary upper limit for demonstration.
        },
      ],
    };
  },
  computed: {
    // Determine the current tier based on the user count
    currentTier() {
      if (this.userCount < 500) {
        return 5;
      } else if (this.userCount < 1000) {
        return 4;
      } else if (this.userCount < 2500) {
        return 3;
      } else if (this.userCount < 5000) {
        return 2;
      } else {
        return 1;
      }
    },
    // Map tiers to include the isCurrent flag dynamically
    updatedTiers() {
      return this.tiers.map((tier) => ({
        ...tier,
        isCurrent: tier.tier === this.currentTier,
      }));
    },
  },
  mounted() {
    this.fetchUserCount();
  },
  methods: {
    fetchUserCount() {
      //  Replace with your actual API endpoint
      axios
        .get(this.$store.getters["getServerURL"] + "/public/getDocCount")
        .then((response) => {
          // Assuming the response returns an object { count: number }
          this.userCount = response.data.count;
        })
        .catch((error) => {
          console.error("Error fetching user count:", error);
        });
    },
    // Logarithmic progress computation for a given tier
    getProgress(tier) {
      if (this.userCount <= tier.minUsers) {
        return 0;
      } else if (this.userCount >= tier.maxUsers) {
        return 100;
      } else {
        // Use natural logarithm for the logarithmic scale.
        const progress =
          ((Math.log(this.userCount) - Math.log(tier.minUsers)) /
            (Math.log(tier.maxUsers) - Math.log(tier.minUsers))) *
          100;
        return Math.round(progress);
      }
    },
  },
};
</script>

<style scoped>
/* Horizontal timeline container */
.timeline-horizontal {
  position: relative;
  padding: 2rem 0;
  align-items: stretch;
}

/* Central horizontal line */
.timeline-horizontal::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5%;
  right: 5%;
  height: 4px;
  background: #ccc;
  z-index: 0;
}

/* Each timeline item as a flex column to equalize card heights */
.timeline-item-horizontal {
  position: relative;
  z-index: 1;
  flex: 1;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Card styles with equal height support */
.tier-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: none;
  border-radius: 10px;
  background: #fff;
  flex: 1;
  position: relative;
}

/* Card hover effect */
.tier-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Highlight the current offer card */
.current-offer .tier-card {
  border: 2px dotted #4144ff !important;
  background-color: rgb(255, 255, 255);
  transform: scale(1.1);
}

/* Disabled offer styling */
.disabled-offer .tier-card .card-body {
  filter: grayscale(100%) opacity(0.95);
}

.disabled-offer .tier-card .card-footer {
  filter: grayscale(100%) opacity(0.95);
}
.disabled-offer .tier-card .card-body:hover {
  filter: grayscale(0%) opacity(1);
}

.upcoming-offer .tier-card .card-body {
  filter: grayscale(74%) opacity(0.95);
}

.upcoming-offer .tier-card .card-footer {
  filter: grayscale(74%) opacity(0.95);
}
.upcoming-offer .tier-card .card-body:hover {
  filter: grayscale(0%) opacity(1);
}

/* Timeline indicator (circle below each card) */
.timeline-indicator {
  width: 20px;
  height: 20px;
  background: #0d6efd;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: 0 auto;
}

/* Stamp styling for missed offers */
.stamp {
  width: 60%;
  position: absolute;
  top: 35%;
  left: 20%;
  transform: translate(-50%, -50%);
  color: #555;
  z-index: 100;
  font-size: 1.5rem;
  font-weight: 700;
  border: 0.2rem double #d23;
  background: #ffe9e9ff;
  backdrop-filter: blur(2px);
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: "Courier";
  -webkit-mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");
  -webkit-mask-size: 944px 604px;
  mix-blend-mode: multiply;
  box-shadow: 0px 0px 20px;
}

.is-nope {
  color: #d23;
  border: 0.2rem double #d23;
  transform: rotate(-15deg);
  -webkit-mask-position: 2rem 3rem;
  font-size: 1.5rem;
  background: #ffe9e9ff;
  backdrop-filter: blur(2px);
}

.is-approved {
  color: #0a9928;
  border: 0.5rem solid #0a9928;
  -webkit-mask-position: 13rem 6rem;
  transform: rotate(-14deg);
  border-radius: 0;
}

.is-draft {
  color: #c4c4c4;
  border: 1rem double #c4c4c4;
  transform: rotate(-5deg);
  font-size: 6rem;
  font-family: "Open sans", Helvetica, Arial, sans-serif;
  border-radius: 0;
  padding: 0.5rem;
}

/* Responsive adjustments for mobile devices */
@media (max-width: 767.98px) {
  .timeline-horizontal {
    flex-direction: column;
    align-items: center;
  }
  .timeline-horizontal::before {
    display: none;
  }
  .timeline-item-horizontal {
    margin-bottom: 2rem;
  }
}
</style>
