<template>
  <div class="container mt-1 pt-2 dashboard-container-fluid">
    <div class="row mt-3">
      <div class="col-12">
        <h4><span class="fa fa-robot"></span> AI Agents</h4>
        <p class="text-sm">
          This page lists all the AI models available to deploy for your AI
          Agent.
        </p>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12 d-flex justify-content-center mt-8" v-if="loading">
        <Spinner />
      </div>

      <template v-if="models.length == 0 && !loading">
        <div class="col-12 text-center my-7 py-4" style="margin-top: 50px">
          <font-awesome-icon icon="fa-solid fa-circle-info" class="fa-2x" />
          <h4>There are no AI Models to deploy!</h4>
          <p>Check back later.</p>
        </div>
      </template>
      <template v-else>
        <h5 class="mt-4">
          Text models
          <span class="badge badge-primary badge-sm">{{
            filterModelsByType("Text").length
          }}</span>
        </h5>
        <hr class="horizontal dark mb-0" />
        <div
          v-for="model in filterModelsByType('Text')"
          :key="model.id"
          class="col-md-4 mt-4"
        >
          <AIAgentCard :model="model" />
        </div>

        <h5 class="mt-4">
          Image models
          <span class="badge badge-primary badge-sm">{{
            filterModelsByType("Image").length
          }}</span>
        </h5>
        <hr class="horizontal dark mb-0" />
        <div
          v-for="model in filterModelsByType('Image')"
          :key="model.id"
          class="col-md-4 mt-4"
        >
          <AIAgentCard :model="model" />
        </div>

        <h5 class="mt-4">
          Audio models
          <span class="badge badge-primary badge-sm">{{
            filterModelsByType("Audio").length
          }}</span>
        </h5>
        <hr class="horizontal dark mb-0" />
        <div
          v-for="model in filterModelsByType('Audio')"
          :key="model.id"
          class="col-md-4 mt-4"
        >
          <AIAgentCard :model="model" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
import AIAgentCard from "./components/AIAgentCard.vue";
import AI from "../../components/Icon/AI.vue";

export default {
  name: "Buckets",
  components: {
    AIAgentCard,
    Spinner,
    AI,
  },
  mounted() {
    // this.$store.state.isAbsolute = false;
    // this.$store.state.isNavFixed = false;
    // setNavPills();
    //fetch ai models

    this.getAIModels();
  },
  data() {
    return {
      models: [],
      loading: false,
    };
  },
  methods: {
    getAIModels() {
      axios
        .get(
          this.$store.getters["getServerURL"] + "/org/aIAgents/getAllModels",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.models = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterModelsByType(type) {
      return this.models.filter((model) => model.modelType === type);
    },
  },
};
</script>

<style></style>
