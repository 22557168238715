import { createRouter, createWebHistory } from "vue-router";


import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import Default from "../views/dashboards/Default.vue";
import Apps from "../views/apps/Apps.vue"
import CreateApp from "../views/apps/CreateApp.vue";
import FileManager from "../views/filemanager/FileManager.vue";
import Buckets from "../views/buckets/Buckets.vue";
import Logout from "../views/auth/Logout.vue";
import HomePage from "../views/home/Index.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import Basic from "../views/auth/signin/Basic.vue";
import ResetBasic from "../views/auth/reset/Basic.vue";
import ActivationBasic from "../views/auth/activation/Basic.vue";
import ResendActivation from "../views/auth/activation/ResendActivation.vue"

import PrivacyPolicy from "../views/privacy-tos/PrivacyPolicy.vue";
import TOS from "../views/privacy-tos/TermsOfUse.vue";
import Admin from "../views/admin/Admin.vue";
import AdminUsers from "../views/admin/users/Users.vue";
import AdminViewUser from "../views/admin/users/view/ViewUser.vue";
import AdminMangeCredits from "../views/admin/credits/ManageCredits.vue";
import AdminSummary from "../views/admin/summary/Summary.vue";

import RentMachine from "../views/rentMachine/RentMachine.vue";
import Compute from "../views/compute/Compute.vue";
import Collection from "../views/compute/Collection.vue";
import RentMachineDashboard from "../views/rentMachineDashboard/RentMachineDashboard.vue";
import AIAgents from "../views/aiAgents/AIAgents.vue";
import Achievements from "../views/achievements/Achievements.vue";

import NotFound from "../views/pages/404.vue";

const routes = [
  {
    path: "/",
    name: "/",
    component: HomePage,
  },
  {
    path: "/rent-machine",
    name: "Rent Machine",
    component: RentMachine,
  },
  {
    path: "/compute",
    name: "Compute",
    component: Compute,
    meta: {
      requiresAuth: true,
      transition: 'slide-left'
    }
  },
  {
    path: "/compute-rent",
    name: "Rent Machine Dashboard",
    component: RentMachineDashboard,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/achievements",
    name: "Achievements",
    component: Achievements,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/compute/collection/:id",
    name: "Compute Collection",
    component: Collection,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/ai-agents",
    name: "Deploy AI Agents",
    component: AIAgents,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/privacy",
    component: PrivacyPolicy
  },
  {
    path: "/tos",
    component: TOS
  },
  {
    path: "/dashboard/",
    name: "Dashboard",
    component: Default,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/account/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/apps",
    name: "View Apps",
    component: Apps,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/apps/create",
    name: "Create App",
    component: CreateApp,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/buckets",
    name: "Buckets",
    component: Buckets,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/buckets/filemanager/:id",
    name: "File Manager",
    component: FileManager,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/billing/invoice",
    name: "Invoice",
    component: Invoice,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/authentication/signup/",
    name: "Signup Basic",
    component: SignupBasic,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/authentication/signin/",
    name: "Signin Basic",
    component: Basic,
    meta: {
      requiresAuth: false
    }

  },
  {
    path: "/authentication/reset/",
    name: "Reset Basic",
    component: ResetBasic,
  },
  {
    path: "/authentication/logout/",
    name: "Logout",
    component: Logout,
  },

  {
    path: "/authentication/activation/",
    name: "Account Activation",
    component: ActivationBasic,
    meta: {
      requiresAuth: false
    }

  },
  {
    path: "/authentication/resend-activation/",
    name: "Resend Account Activation Mail",
    component: ResendActivation,
    meta: {
      requiresAuth: false
    }

  },

  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404,
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500,
  },

  {
    path: "/admin",
    name: "AdminHome",
    component: Admin,
    meta: {
      requiresAuth: true,
      adminRoute: true
    }
  },
  {
    path: "/admin/summary",
    name: "AdminSummary",
    component: AdminSummary,
    meta: {
      requiresAuth: true,
      adminRoute: true
    }
  },

  {
    path: "/admin/users",
    name: "AdminUsers",
    component: AdminUsers,
    meta: {
      requiresAuth: true,
      adminRoute: true
    }
  },

  {
    path: "/admin/users/view/:userID",
    name: "AdminViewUser",
    component: AdminViewUser,
    meta: {
      requiresAuth: true,
      adminRoute: true
    }
  },

  {
    path: "/admin/users/manage-credits/:userID",
    name: "AdminMangeCredits",
    component: AdminMangeCredits,
    meta: {
      requiresAuth: true,
      adminRoute: true
    }
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound } // Catch-all route for 404


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { left: 0, top: 0 };
  }
});

router.beforeEach((to, from) => {

  //check if no jwt
  if (to.meta.requiresAuth && !localStorage.getItem('jwt')) {
    return { name: 'Signin Basic' }
  }


  //check if jwt has expired
  if (to.meta.requiresAuth && parseFloat(localStorage.getItem('expiresIn')) < parseFloat((Date.now()) / 1000)) {
    localStorage.removeItem("jwt");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("userID");
    localStorage.removeItem("userName");
    localStorage.removeItem("role");
    return { name: 'Signin Basic' }
  }

  //check admin route
  if (to.meta.adminRoute && localStorage.getItem("role") != 1) {
    return { name: 'Default' }
  }

  // default
  return true; // Allow navigation

})

export default router;
