<template>
  <div class="container mt-1 pt-2 dashboard-container-fluid">
    <div class="row mt-3">
      <div class="col-md-9">
        <h4 class=""><i class="fa fa-trophy"></i> Achievements</h4>
        <p>Your current tier:</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 mt-2 mb-4 glow-box" data-aos="fade-up">
        <CurrentUserTier
          :earnings="
            user.computeEarnings + user.referralEarnings + user.badgeEarnings
          "
          :userTier="user.userTier"
        />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <h5>Account Tier Ladder</h5>
        <p class="text-sm">Account tiers are unlocked as your earn xCP.</p>
        <div class="row mt-4" data-aos="fade-up">
          <div class="col-md-12 mb-4 mt-2" v-if="userTiers.length == 0">
            <Skeleton width="80%" height="20px" class="mt-2"></Skeleton>
            <Skeleton width="50%" height="20px" class="mt-2"></Skeleton>
            <Skeleton width="80%" height="20px" class="mt-2"></Skeleton>
            <Skeleton width="50%" height="20px" class="mt-2"></Skeleton>
          </div>

          <div class="col-md-2 mb-4" v-for="tier in userTiers" :key="tier._id">
            <TierBadge
              :name="tier.name"
              :tier="tier.tier"
              :icon="'tier' + tier.tier + '.png'"
              :requiredValue="tier.pointsRequired"
              criteria="xCP Earned"
              :currentTier="user.userTier"
              :tierBenefit="tier.tierBenefit"
            />
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-4" data-aos="fade-up">
        <h5>Available Badges</h5>
        <p class="text-sm">
          Badges are unlocked by completing certain tasks such as activating
          machines, referring people etc.
        </p>
        <div class="row mt-4">
          <div
            class="col-md-12 mb-4 mt-2"
            v-if="unlockedBadges.length == 0 && lockedBadges.length == 0"
          >
            <Skeleton width="80%" height="20px" class="mt-2"></Skeleton>
            <Skeleton width="50%" height="20px" class="mt-2"></Skeleton>
            <Skeleton width="80%" height="20px" class="mt-2"></Skeleton>
            <Skeleton width="50%" height="20px" class="mt-2"></Skeleton>
          </div>
          <div
            class="col-md-2 mb-4"
            v-for="badge in unlockedBadges"
            :key="badge._id"
          >
            <Badge
              :name="badge.name"
              :description="badge.description"
              :reward="badge.reward"
              :requiredValue="badge.requiredValue"
              :criteria="badge.criteria"
              :icon="badge.icon"
              :unlocked="badge.unlocked"
            />
          </div>

          <div
            class="col-md-2 mb-4"
            v-for="badge in lockedBadges"
            :key="badge._id"
          >
            <Badge
              :name="badge.name"
              :description="badge.description"
              :reward="badge.reward"
              :requiredValue="badge.requiredValue"
              :criteria="badge.criteria"
              :icon="badge.icon"
              :unlocked="badge.unlocked"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <ReferralTable :referrals="referrals" />
      </div>
    </div>
  </div>
</template>
<script>
import ReferralTable from "./components/ReferralTable.vue";
import axios from "axios";
import Badge from "./components/Badge.vue";
import TierBadge from "./components/TierBadge.vue";
import Skeleton from "primevue/skeleton";
import CurrentUserTier from "../rentMachineDashboard/components/CurrentUserTier.vue";

export default {
  name: "Achievements",
  components: {
    ReferralTable,
    Badge,
    TierBadge,
    Skeleton,
    CurrentUserTier,
  },
  data() {
    return {
      unlockedBadges: [],
      lockedBadges: [],
      userTiers: [],
      user: {
        computeEarnings: 0,
        email: "",
        name: "",
        referralCode: "",
        referralEarnings: 0,
        badgeEarnings: 0,
        userTier: 1,
      },
      referrals: [],
    };
  },
  mounted() {
    this.getUserBadges();
    this.getUserTiers();
    this.getUser();
    this.getReferrals();
  },
  methods: {
    getUserBadges() {
      axios
        .get(
          this.$store.getters["getServerURL"] + "/org/badges/getUserBadges",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.unlockedBadges = response.data.unlockedBadges;
          this.lockedBadges = response.data.lockedBadges;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUserTiers() {
      axios
        .get(
          this.$store.getters["getServerURL"] + "/org/rentCompute/getUserTiers",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((resp) => {
          // console.log("tiers", resp.data);
          this.userTiers = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getReferrals() {
      axios
        .get(
          this.$store.getters["getServerURL"] + "/referral/getAllReferrals",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.referrals = response.data;
          this.referrals.reverse();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUser() {
      axios
        .get(this.$store.getters["getServerURL"] + "/org/user/getUser", {
          headers: { Authorization: localStorage.getItem("jwt") },
        })
        .then((response) => {
          console.log(response);
          this.user = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
