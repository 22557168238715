<template>
  <aside
    id="sidenav-main"
    class="border-1 sidenav navbar navbar-vertical navbar-expand-xs bg-white border"
    style="margin: 1rem; margin-left: 1rem; border-radius: 10px"
  >
    <div class="sidenav-header">
      <router-link
        class="m-0 navbar-brand"
        :class="{ 'p-0 pt-3 px-4': !isPinned }"
        to="/"
      >
        <img
          :src="isPinned ? '/images/full_logo.png' : '/images/icon.png'"
          class="navbar-brand-img d-block mx-auto"
          :class="{ 'border-radius-lg': !isPinned }"
          alt="main_logo"
        />
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :card-bg="customClass" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";

import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["isPinned"]),
  },
};
</script>
<style>
#sidenav-main {
  z-index: 10;
}
.navbar-vertical .navbar-brand > img,
.navbar-vertical .navbar-brand-img {
  max-width: 100%;
  max-height: 3rem !important;
}
</style>
