<template>
  <div class="card highlight-card h-100" data-aos="slide-up">
    <div class="px-3 pt-4 card-body text-center">
      <span v-if="active == 0" class="badge badge-warning badge-sm"
        >Inactive</span
      >
      <span v-if="active == 1" class="badge badge-primary badge-sm"
        >Active</span
      >
      <span v-if="active == 2" class="badge badge-danger badge-sm"
        >Suspended</span
      >
      <h6 class="mb-0 mt-2 text-center">{{ appName }}</h6>
      <p class="text-xs">
        {{ appDescription }}
      </p>
    </div>

    <div
      class="card-footer bg-primary text-white p-0 m-0 py-2 d-flex justify-content-center"
    >
      <button
        class="btn btn-link p-1 m-0 d-block mx-auto text-white"
        @click="this.$router.push('/compute/collection/' + appId)"
      >
        View Collection
      </button>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-boring-avatars";

export default {
  name: "ComputeCard",
  components: {
    Avatar,
  },
  props: {
    appName: {
      type: String,
      required: true,
    },
    active: {
      type: Number,
      default: 1,
    },
    appId: {
      type: String,
    },
    appIndentifier: {
      type: String,
      default: "",
    },
    appDescription: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
