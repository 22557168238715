<template>
  <div class="container mt-1 pt-2 dashboard-container-fluid">
    <div class="row mt-3">
      <div class="col-9">
        <h4><i class="fa fa-server"></i> Compute Collections</h4>
        <p class="text-sm">
          This page lists all the compute collections automatically created for
          your active Apps. Each Compute Collection is tied to a specific app,
          identified by its name, and ID.
        </p>
      </div>
      <div class="col-3 d-flex align-items-center">
        <input
          class="form-control"
          list="datalistOptions"
          id="exampleDataList"
          autocomplete="off"
          placeholder="🔎 Search your compute collections..."
          v-model="searchTerm"
        />
      </div>
    </div>
    <div class="row mb-5 mt-4">
      <div class="col-12 d-flex justify-content-center mt-8" v-if="loading">
        <Spinner />
      </div>

      <template v-if="apps.length == 0 && !loading">
        <div class="col-12 text-center" style="margin-top: 50px">
          <font-awesome-icon icon="fa-solid fa-circle-info" class="fa-2x" />
          <h4>There are no Compute Collection.</h4>
          <p>
            Compute Collections are automatically created for your apps. To
            create a Compute Collection create an App.
          </p>
          <button
            class="btn btn-primary"
            @click="this.$router.push('/apps/create')"
          >
            + Create App
          </button>
        </div>
      </template>
      <template v-else>
        <div
          class="mb-4 col-lg-3 col-md-3"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <place-holder-card
            :title="{ text: 'New App', variant: 'h5' }"
            subtitle="To create a Compute Collection, create an App."
          />
        </div>
        <div v-for="app in filterApps()" :key="app._id" class="mb-4 col-md-3">
          <ComputeCard
            :appName="app.appName"
            :active="app.active"
            :appDescription="app.appDescription"
            :appId="app._id"
            :appIndentifier="app.appIdentifier"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ComputeCard from "./components/ComputeCard.vue";
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
import PlaceHolderCard from "./components/PlaceHolderCard.vue";
export default {
  name: "Buckets",
  components: {
    ComputeCard,
    PlaceHolderCard,
    Spinner,
  },
  mounted() {
    // this.$store.state.isAbsolute = false;
    // this.$store.state.isNavFixed = false;
    // setNavPills();

    //fetch apps
    axios
      .get(this.$store.getters["getServerURL"] + "/org/apps/getAllApps", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        // console.log(response);
        this.loading = false;
        this.apps = response.data;

        this.apps.reverse();
      })
      .catch((err) => {
        //    console.log(err);
      });
  },
  data() {
    return {
      apps: [],
      loading: true,
      searchTerm: null,
    };
  },
  methods: {
    filterApps() {
      if (!this.searchTerm) {
        return this.apps;
      }
      const lowerCaseSearchTerm = this.searchTerm.toLowerCase();

      return this.apps.filter((item) => {
        if (typeof item === "string") {
          return item.toLowerCase().includes(lowerCaseSearchTerm);
        } else if (typeof item === "object") {
          return item.appName
            .toString()
            .toLowerCase()
            .includes(lowerCaseSearchTerm);
        }
        return false;
      });
    },
  },
};
</script>

<style></style>
