<template>
  <div
    class="container py-4 dashboard-container-fluid"
    :style="{
      'background-image': 'url(' + BGFeatures + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
      'background-position': 'bottom 0px',
    }"
  >
    <div class="row mt-3">
      <div class="col-12">
        <h3><i class="fa fa-dollar"></i> Billing for Storage</h3>
        <p class="text-sm">
          This page displays the total data egress and ingress for the current
          month, along with the amount of data stored. You can view their
          available credits and the total amount payable for the month in $XAP.
          Additionally, this page also lists all the previous invoices.
        </p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="row mt-4">
          <div class="col-md-3">
            <div class="card bg-white">
              <div class="card-body text-dark">
                <p>Payable this month:</p>
                <h2 class="text-dark">{{ calculatePayable() }} $XAP</h2>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row h-100">
              <div
                class="col-md-3"
                data-aos="fade-right"
                data-aos-once="true"
                data-aos-delay="200"
              >
                <default-counter-card
                  :count="formatBytes(currentInvoice.dataEgress).num || 0"
                  :suffix="formatBytes(currentInvoice.dataEgress).suffix || ''"
                  color="dark"
                  cardColor="white"
                  title="Egress this month"
                  description="Updated every 5 mins."
                />
              </div>
              <div
                class="col-md-3"
                data-aos="fade-right"
                data-aos-once="true"
                data-aos-delay="400"
              >
                <default-counter-card
                  :count="formatBytes(currentInvoice.dataIngress).num || 0"
                  :suffix="formatBytes(currentInvoice.dataIngress).suffix || ''"
                  color="dark"
                  cardColor="white"
                  title="Ingress this month"
                  description="Updated every 5 mins."
                />
              </div>
              <div
                class="col-md-3"
                data-aos="fade-right"
                data-aos-once="true"
                data-aos-delay="600"
              >
                <default-counter-card
                  :count="formatBytes(currentInvoice.dataStorage).num || 0"
                  :suffix="formatBytes(currentInvoice.dataStorage).suffix || ''"
                  color="dark"
                  cardColor="white"
                  title="Total Data Stored"
                  description="Updated every 5 mins."
                />
              </div>
              <div
                class="col-md-3"
                data-aos="fade-right"
                data-aos-once="true"
                data-aos-delay="800"
              >
                <default-counter-card
                  :count="getExpiringCredits()"
                  suffix="$XAP"
                  color="dark"
                  :alwaysCount="false"
                  title="Credits remaining"
                  description="First to expire."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <h4 class="mt-4 font-weight-bolder">Credit and Invoice History</h4>

      <div class="col-lg-8" data-aos="fade-up" data-aos-once="true">
        <div
          v-if="invoicesLoading"
          class="bg-white px-4 py-5 mt-4"
          style="border: 1px solid #ddd"
        >
          <Skeleton class="mb-2"></Skeleton>
          <Skeleton width="10rem" class="mb-2"></Skeleton>
          <Skeleton width="5rem" class="mb-2"></Skeleton>
        </div>
        <invoice-card :invoices="invoices" v-else />
      </div>
      <div class="col-lg-4" data-aos="fade-up" data-aos-once="true">
        <div
          v-if="creditsLoading"
          class="bg-white px-4 py-5 mt-4"
          style="border: 1px solid #ddd"
        >
          <Skeleton class="mb-2"></Skeleton>
          <Skeleton width="10rem" class="mb-2"></Skeleton>
          <Skeleton width="5rem" class="mb-2"></Skeleton>
        </div>
        <credits-card :credits="credits" v-else />
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import NavPill from "./components/NavPill.vue";
import BGFeatures from "@/assets/img/bg-features.png";

import InvoiceCard from "./components/InvoiceCard.vue";
import BillingCard from "./components/BillingCard.vue";

import DefaultCounterCard from "../../../examples/Cards/DefaultCounterCard.vue";
import CreditsCard from "./components/CreditsCard.vue";
import axios from "axios";
import Toast from "primevue/toast";

import Skeleton from "primevue/skeleton";
import { faL } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "Billing",
  components: {
    NavPill,

    InvoiceCard,
    BillingCard,

    DefaultCounterCard,
    CreditsCard,
    Toast,
    Skeleton,
  },
  async mounted() {
    //get current invoice
    try {
      const curreInvoiceResponse = await axios.get(
        this.$store.getters["getServerURL"] + "/org/getCurrentInvoice",
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      );
      this.currentInvoice = curreInvoiceResponse.data;
      console.log(this.currentInvoice);
    } catch (err) {
      this.$toast.add({
        severity: "error",
        summary: "Error!",
        detail: "Unable to fetch current invoice. Try again later.",
        life: 5000,
      });
    }

    //get all invoices
    axios
      .get(this.$store.getters["getServerURL"] + "/org/getAllInvoices", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        this.invoices = response.data;
        // console.log(response.data);
        this.invoicesLoading = false;
      })
      .catch((err) => {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Unable to fetch invoices. Try again later.",
          life: 5000,
        });
      });

    //get credits
    axios
      .get(this.$store.getters["getServerURL"] + "/org/getCredits", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        this.credits = response.data;
        this.creditsLoading = false;
      })
      .catch((err) => {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Unable to fetch credits. Try again later.",
          life: 5000,
        });
      });
  },
  data() {
    return {
      BGFeatures: BGFeatures,
      credits: [
        {
          creditType: {
            credit: 0,
          },
        },
      ],
      invoices: [],
      currentInvoice: {
        payable: 0,
      },
      invoicesLoading: true,
      creditsLoading: true,
    };
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return {
        num: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
        suffix: sizes[i],
      };
    },
    getExpiringCredits() {
      if (!this.credits) {
        return 0;
      }
      var index = 0;
      if (this.credits.length == 0) {
        return 0;
      }
      if (this.credits.length == 1) {
        return (
          parseFloat(this.credits[index].creditType.credits) / 1000000
        ).toFixed(2);
      }

      for (var i = 1; i < this.credits.length; i++) {
        if (
          this.credits[i].creditType.expiresOn <
          this.credits[index].creditType.expiresOn
        ) {
          index = i;
        }
      }
      // console.log(
      //   parseFloat(this.credits[index].creditType.credits).toFixed(2)
      // );
      return (
        parseFloat(this.credits[index].creditType.credits) / 1000000
      ).toFixed(2);
    },
    calculatePayable() {
      if (!this.currentInvoice) {
        return 0;
      }
      return (parseFloat(this.currentInvoice.payable) / 1000000).toFixed(5);
    },
  },
};
</script>
