<template>
  <div class="container mt-1 pt-2">
    <div class="row mt-3">
      <div class="col-md-9">
        <h4><i class="fa fa-server"></i> Rent your machine</h4>
        <p class="text-sm">
          You can manage your connected machines from this page. Your earnings
          from sharing compute and referrals are also listed here.
        </p>
      </div>

      <div class="col-md-3 d-flex align-items-center justify-content-end">
        <button
          class="btn btn-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#addMachine"
        >
          + Add Machine
        </button>
      </div>
    </div>

    <!-- Quick stats -->
    <div class="row mt-3">
      <div class="col-md-3 mt-2 glow-box" data-aos="fade-up">
        <CurrentUserTier
          :earnings="
            earnings.computeEarnings +
            earnings.referralEarnings +
            earnings.badgeEarnings
          "
          :userTier="earnings.userTier"
        />
      </div>

      <div class="col-md-3 mt-2" data-aos="fade-up" data-aos-delay="250">
        <default-counter-card
          v-tooltip.top="{
            value: 'Compute + Referral + Badge Earnings',
            autoHide: false,
          }"
          :count="
            earnings.computeEarnings +
            earnings.referralEarnings +
            earnings.badgeEarnings
          "
          suffix="xCP"
          description="Overall"
          shadow="no-shadow"
          color="dark"
          cardColor="white"
          title="Lifetime Earnings"
        />
      </div>
      <div class="col-md-3 mt-2" data-aos="fade-up" data-aos-delay="500">
        <default-counter-card
          v-tooltip.top="{
            value: 'Compute + Referral + Badge Earnings',
            autoHide: false,
          }"
          :count="earnings.todaysEarnings"
          suffix="xCP"
          description="Overall"
          shadow="no-shadow"
          color="dark"
          cardColor="white"
          title="Today's Earnings"
        />
      </div>

      <!-- Online Status -->
      <div class="col-md-3 mt-2" data-aos="fade-up" data-aos-delay="750">
        <div class="card h-100">
          <div class="card-body text-center py-3">
            <h6 class="mt-0 pt-0">Connection Status</h6>
            <h6
              class="text-success mt-0 my-3 py-1 mx-auto"
              style="border: 1px dotted green; width: 50%; border-radius: 10px"
              v-if="deviceOnline"
            >
              <i class="fa fa-circle-dot fa-beat"></i>
              Online
            </h6>
            <h6
              class="text-muted mt-0 my-3 py-1 mx-auto"
              style="border: 1px dashed gray; width: 50%"
              v-else
            >
              <i class="fa fa-circle-dot"></i>
              Offline
            </h6>

            <p class="py-0 my-0 mt-2 text-sm">
              At least 1 machine should be online.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings -->
    <div class="row mt-5">
      <div class="col-lg-12">
        <h5>Earnings Overtime (Last 30 days)</h5>
        <p class="py-0 text-sm mb-4">
          Earnings are updated after polling data from various services and can
          take upto 12 hrs to reflect.
        </p>
        <Chart
          v-if="earnings.computeEarnings || earnings.referralEarnings"
          type="bar"
          :data="chartData"
          :options="chartOptions"
          :height="300"
          style="
            padding: 40px;
            background-color: white;
            border-radius: 10px;
            border: 1px solid #ddd;
          "
          data-aos="fade-up"
        />

        <div
          class="w-100 text-center justify-content-center align-self-center py-6"
          data-aos="fade-up"
          v-else
        >
          <h4><i class="fa-solid fa-chart-simple fa-2x"></i></h4>
          <h4 class="h-100">No History!</h4>
          <p>Keep your machines online or refer people to start earning.</p>
        </div>
      </div>
    </div>

    <!-- Your Machines -->
    <div class="row mt-5">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-10">
            <h5>Your Machines</h5>
            <p class="text-sm">All your machines are listed here.</p>
          </div>

          <div class="col-md-2 d-flex align-items-center justify-content-end">
            <button
              class="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#addMachine"
            >
              + Add Machine
            </button>
          </div>
        </div>
        <div class="row" data-aos="fade-up">
          <div class="table-responsive" v-if="machines.length > 0">
            <Machines :machines="machines"></Machines>
          </div>
          <div
            class="w-100 text-center justify-content-center align-self-center py-6"
            v-else
          >
            <h4><i class="fa-solid fa-desktop fa-2x"></i></h4>
            <h4 class="h-100">No Machines!</h4>
            <p>Click on + Add Machine to start renting compute.</p>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>

  <!-- Add Machine Modal -->
  <div
    class="modal"
    id="addMachine"
    aria-labelledby="addMachineModalLabel"
    aria-hidden="true"
    ref="addMachineModal"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="">Add Machine</h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12" v-if="!newMachine">
              <!-- <img
                :src="require('@/assets/img/add-machine.png')"
                alt="ExaDrive Logo"
                class="product-logo"
                
              /> -->
              <p>
                Enter name and description of your machine. <br />Machine token
                will be generated after you add your machine.
              </p>
              <hr class="horizontal dark my-4" />
              <form
                id="addCreditsForm"
                role="form"
                @submit.prevent="addMachine()"
              >
                <label for="machineName" class="form-label"
                  >Machine Name (3-63 chars)</label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    name="machineName"
                    placeholder="Home Computer"
                    class="form-control"
                    id="machineName"
                    v-model="addMachineName"
                    aria-label="Machine Name"
                    autocomplete="off"
                  />
                </div>

                <label for="machineDescription" class="form-label"
                  >Machine Description (3-63 chars)</label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    name="machineDescription"
                    placeholder="Home computer with GPU RTX 307, 16GB RAM..."
                    class="form-control"
                    id="machineDescription"
                    v-model="addMachineDescription"
                    aria-label="Machine Description"
                    autocomplete="off"
                  />
                </div>

                <hr class="horizontal dark my-4" />

                <div class="my-4">
                  <p class="font-weight-bold">Please verify reCaptcha:</p>
                  <RecaptchaV2
                    @widget-id="handleWidgetId"
                    @error-callback="handleErrorCalback"
                    @expired-callback="handleExpiredCallback"
                    @load-callback="handleLoadCallback"
                  />
                </div>
                <button
                  type="submit"
                  class="btn btn-primary mt-4"
                  id="addMachineButton"
                  :disabled="disableAddMachineBtn"
                >
                  + Add Machine
                </button>
              </form>
            </div>
            <div class="col-md-12" v-else>
              <img
                :src="require('@/assets/img/check.png')"
                class="d-block mx-auto my-4"
                style="width: 50px"
                alt=""
              />
              <h5 class="text-center">Machine Added!</h5>
              <p class="text-sm text-center">
                <b>Name:</b> {{ newMachine.machineName }}<br />
                <b>Description:</b>
                {{ newMachine.machineDescription }}
              </p>

              <hr class="horizontal dark my-4" />
              <div class="text-center pb-4 px-4">
                <Inplace>
                  <template #display>Click here to view Machine Token</template>
                  <template #content>
                    <div>
                      <code class="text-dark font-weight-bold">{{
                        newMachine.machineToken || "Hello"
                      }}</code>
                    </div>
                  </template>
                </Inplace>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

import axios from "axios";
import Toast from "primevue/toast";
import DefaultCounterCard from "../../examples/Cards/DefaultCounterCard.vue";
import Chart from "primevue/chart";
import Skeleton from "primevue/skeleton";
import { RecaptchaV2, useRecaptcha } from "vue3-recaptcha-v2";
const { handleReset } = useRecaptcha();
import Machines from "./components/Machines.vue";
import Image from "primevue/image";
import CurrentUserTier from "./components/CurrentUserTier.vue";
import Inplace from "primevue/inplace";
export default {
  name: "RentMachineDashboard",
  components: {
    Toast,
    Skeleton,
    DefaultCounterCard,
    Chart,
    RecaptchaV2,
    Machines,
    Image,
    CurrentUserTier,
    Inplace,
  },
  async mounted() {
    this.chartOptions = this.setChartOptions();
    this.getMachines();
    this.getEarnings();
    // Create a Bootstrap modal instance
    this.modalInstance = new bootstrap.Modal(this.$refs.addMachineModal);

    // Listen for modal close event to stop video
    this.$refs.addMachineModal.addEventListener("hidden.bs.modal", () => {
      this.clearNewMachine();
    });
  },
  data() {
    return {
      chartData: null,
      chartOptions: null,
      addMachineName: "",
      addMachineDescription: "",
      captchaResponseMessage: "",
      captchaResponse: "",
      widgetID: "",
      machines: [],
      newMachine: null,
      disableAddMachineBtn: false,
      earnings: {
        computeEarnings: 0,
        referralEarnings: 0,
        badgeEarnings: 0,
        todaysEarnings: 0,
        userTier: 1,
      },
      deviceOnline: false,
      tierImage: null,
      modalInstance: null,
    };
  },
  computed: {},
  methods: {
    handleWidgetId(widgetId) {
      // console.log("Widget ID: ", widgetId);
      this.widgetID = widgetId;
    },
    handleErrorCalback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleExpiredCallback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleLoadCallback(response) {
      // console.log("Load callback", response);
      this.captchaResponseMessage = response;
      this.captchaResponse = true;
    },
    addMachine() {
      this.disableAddMachineBtn = true;
      axios({
        method: "post",
        url:
          this.$store.getters["getServerURL"] + "/org/rentCompute/addMachine",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          machineName: this.addMachineName,
          machineDescription: this.addMachineDescription,
          gRecaptchaResponse: this.captchaResponseMessage,
        },
      })
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Machine updated! Reloading page...",
            life: 2000,
          });

          this.$confetti.start();
          setTimeout(() => {
            this.$confetti.stop();
          }, 1000);

          this.newMachine = resp.data;
          this.machines.unshift(this.newMachine);
          // setTimeout(() => {
          //   this.$router.go(this.$router.currentRoute);
          // }, 2000);
        })
        .catch((err) => {
          this.disableAddMachineBtn = false;
          handleReset(this.widgetID);
          // console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Error while adding a new machine. \n" +
              err.response.data.errors[0] +
              (err.response.data.errors[1]
                ? "\n" + err.response.data.errors[1]
                : ""),
            life: 5000,
          });
        });
    },
    clearNewMachine() {
      this.newMachine = null;
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    getMachines() {
      axios
        .get(
          this.$store.getters["getServerURL"] +
            "/org/rentCompute/getAllMachines",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.machines = response.data;
          this.machines.reverse();
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getEarnings() {
      axios
        .get(
          this.$store.getters["getServerURL"] + "/org/rentCompute/getEarnings",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.earnings.computeEarnings = response.data.computeEarnings;
          this.earnings.referralEarnings = response.data.referralEarnings;
          this.earnings.todaysEarnings = response.data.chartData.todaysEarnings;
          this.earnings.badgeEarnings = response.data.badgeEarnings;
          this.earnings.userTier = response.data.userTier;
          this.chartData = response.data.chartData;
          this.deviceOnline = response.data.chartData.deviceOnline;
          this.setChartOptions();

          // console.log(this.chartData);
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    copyText(text) {
      var context = this;
      navigator.clipboard.writeText(text).then(
        function () {
          context.$toast.add({
            severity: "success",
            summary: "Copied",
            detail: "Copied to clipboard:\n" + text,
            life: 5000,
          });
        },
        function (err) {
          context.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to copy CDN URL:\n" + text,
          });
        }
      );
    },

    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--p-text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--p-text-muted-color"
      );
      const surfaceBorder = documentStyle.getPropertyValue(
        "--p-content-border-color"
      );

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          tooltips: {
            mode: "index",
            intersect: false,
          },
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };
    },
  },
};
</script>

<style>
.no-shadow {
  box-shadow: none;
}
.user-badge-xl {
  width: 140px;
}
.user-badge-top-sticker {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -70px; /* 50% of 100px image height */
}
.bg-success-light {
  background-color: #e4e4ff;
}
</style>
