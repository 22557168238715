<template>
  <tr>
    <td
      class="px-4"
      style="width: 20%"
      v-tooltip.bottom="{ value: user.name, autoHide: false }"
    >
      {{ user.name }}
      <br /><span class="text-xs text-primary"> {{ user._id }}</span>
    </td>
    <td
      class="px-4"
      style="width: 20%; border-left: 1px solid #ddd"
      v-tooltip.bottom="{ value: user.email, autoHide: false }"
    >
      {{ user.email }}
    </td>
    <td class="px-4" style="width: 10%">{{ user.active }}</td>

    <td class="px-4" style="width: 10%">{{ user.createdAt }}</td>

    <td style="width: 10%">
      <router-link
        class="btn btn-link btn-sm"
        :to="'/admin/users/manage-credits/' + user._id"
        >Manage Credits</router-link
      >
    </td>
    <td class="px-4" style="width: 10%">
      <router-link
        class="btn btn-primary btn-sm"
        :to="'/admin/users/view/' + user._id"
        >View</router-link
      >
    </td>
  </tr>

  <!-- Update User name and email Modal -->
  <div
    class="modal"
    :id="'updateUser' + user._id"
    aria-labelledby="updateUserLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="text-primary text-xs text-center mb-0 pb-0">
            ID: {{ user._id }} | Email: {{ user.email }}
          </p>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="fa fa-close"></span>
          </button>
        </div>
        <div class="modal-body py-4">
          <div class="mb-2 pr-5">
            <label for="userNameNew" class="form-label">Current Email </label>
            <input
              id="userNameNew"
              type="text"
              class="form-control"
              placeholder=""
              v-model="newEmail"
              disabled
            />
          </div>
          <div class="mb-2 pr-5">
            <label for="userActiveNew" class="form-label"
              >New Active | 1 = Active, 0 = Inactive</label
            >
            <input
              id="userActiveNew"
              type="number"
              min="0"
              max="1"
              class="form-control"
              placeholder=""
              v-model="active"
            />
          </div>

          <div class="mt-4 pr-5">
            <button class="btn btn-primary btn-sm" @click="updateUser()">
              Update User
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Toast />
</template>

<script>
import axios from "axios";
import Toast from "primevue/toast";
export default {
  name: "UserTableRow",
  components: {
    Toast,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      userID: this.user._id,
      newEmail: this.user.email,
      active: this.user.active,
    };
  },
  methods: {
    enableFieldEdit(elementID) {
      const field = document.getElementById(elementID);
      field.disabled = field.disabled ? false : true;
    },
    updateUser() {
      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/admin/updateUser",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          userID: this.userID,
          active: this.active,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail:
              this.user.email + " Updated! \nActive: " + response.data.active,
            life: 2000,
          });

          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped></style>
