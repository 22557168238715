<template>
  <div
    class="container-fluid vh-100 bg-white px-6"
    style="background-image: url('/images/homer.gif'); background-size: cover"
  >
    <div class="row d-flex align-items-center h-100">
      <div class="col-md-4 col-sm-12">
        <div
          class="card shadow"
          style="
            background-color: #00000033;
            backdrop-filter: blur(20px);
            border: 0px;
          "
        >
          <div class="card-body" style="">
            <img
              src="/images/icon.png"
              class="d-block mx-auto border-radius-lg"
              style="width: 75px"
              alt=""
            />
            <img
              src="/images/404.jpg"
              class="img-fluid my-4 border shadow border-radius-lg"
              alt=""
            />
            <br />
            <router-link to="/" class="btn btn-primary d-block"
              >🏡 Back to Home</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "404",

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    this.$confetti.start();
    setTimeout(() => {
      this.$confetti.stop();
    }, 1000);
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>
