<template>
  <!-- Bootstrap Modal -->
  <div
    class="modal"
    id="quickStartModal"
    tabindex="-1"
    aria-hidden="true"
    ref="modalElement"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div
        class="modal-content"
        style="
          background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
        "
      >
        <div class="modal-body px-5">
          <div class="row">
            <div class="col-md-12 text-center mt-2">
              <h4 class="m-0 p- font-weight-bolder">
                How to rent your machine? 🤔
              </h4>
            </div>
            <hr class="horizontal dark my-4" />
            <div class="col-md-4 text-center">
              <div class="card h-100">
                <div class="card-body p-4">
                  <h5>Step 1</h5>
                  <p class="p-0 m-0 text-sm">
                    Download the
                    <a
                      href="https://release.exadrivecdn.com/"
                      class="text-primary bg-light px-2 m-0"
                      target="_blank"
                    >
                      Exa Protocol client.
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 text-center">
              <div class="card h-100">
                <div class="card-body p-4">
                  <h5>Step 2</h5>
                  <p class="p-0 m-0 text-sm">
                    Install the client on your machine.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 text-center">
              <div class="card h-100">
                <div class="card-body p-4">
                  <h5>Step 3</h5>
                  <p class="p-0 m-0 text-sm">
                    Setup the app. <br />Watch the video ⬇️
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card my-4" style="background-color: black">
                <div class="card-body">
                  <iframe
                    id="youtubeVideo"
                    class="mx-auto d-block border-radius-lg"
                    :src="videoSrc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                    referrerpolicy="strict-origin-when-cross-origin"
                    style="width: 100%; height: 300px"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-0 m-0 pt-2 d-flex justify-content-center">
          <a
            class="btn btn-primary"
            href="https://exaprotocol.medium.com/running-a-node-on-exa-protocol-quick-start-guide-957aee54591d"
            target="_blank"
          >
            Read the guide 🔗
          </a>
          <button
            type="button"
            class="btn btn-link text-dark"
            data-bs-dismiss="modal"
          >
            I got this!
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

export default {
  name: "QuickStartModal",
  data() {
    return {
      videoSrc: "https://www.youtube.com/embed/nrHEQpZSuk4?si=L3kucypC0W_r27bW",
      modalInstance: null,
    };
  },
  mounted() {
    // Create a Bootstrap modal instance
    this.modalInstance = new bootstrap.Modal(this.$refs.modalElement);

    // Listen for modal close event to stop video
    this.$refs.modalElement.addEventListener("hidden.bs.modal", () => {
      this.stopYouTubeVideo();
    });

    // Show modal on first visit
    if (!localStorage.getItem("quickStartModalShown")) {
      this.showModal();
      localStorage.setItem("quickStartModalShown", "true"); // Prevent future auto-opens
    }
  },
  methods: {
    showModal() {
      this.modalInstance.show();
    },
    hideModal() {
      this.modalInstance.hide();
    },
    stopYouTubeVideo() {
      const iframe = document.getElementById("youtubeVideo");
      iframe.src = ""; // Stop the video
      setTimeout(() => {
        iframe.src = this.videoSrc; // Restore src for future plays
      }, 500);
    },
  },
};
</script>
