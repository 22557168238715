<template>
  <navbar btn-background="bg-gradient-dark" />

  <!-- WELCOME -->
  <section
    class="page-header align-items-start bg-dark hero-section py-7"
    id="hero-section"
  >
    <span class="mask opacity-9" style="background-color: #0004aa"></span>
    <div class="container py-4">
      <div class="row align-items-center">
        <div
          class="area h-100"
          style="
            z-index: 0;
            background-color: transparent;
            transition: background-color 1s ease;
          "
        >
          <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div class="col-md-6 mt-3 pt-5" data-aos="fade-up" data-aos-once="true">
          <h1
            class="display-4 text-md-start text-white block-effect"
            style="--td: 0.5s"
          >
            <!-- <div class="block-reveal display-6" style="--bc: blue; --d: 0s">
              <i class="fa fa-apple"></i> &nbsp;<i class="fa fa-windows"></i
              >&nbsp;
              <i class="fa fa-linux"></i>
            </div> -->
            <div class="block-reveal" style="--bc: #fff; --d: 0.1s">
              Turn Your Idle Device
            </div>
            <div class="block-reveal" style="--bc: blue; --d: 0.2s">
              Into Passive Income
            </div>
            <div class="block-reveal" style="--bc: #fff; --d: 0.3s">
              with ExaCompute
            </div>
          </h1>

          <!-- Text -->
          <p class="text-md-start text-white text-lg mt-4">
            Earn xCP (Exa Compute Points) by renting your device to the Exa
            Protocol and support decentralized compute power for AI, ML, and
            more.
          </p>

          <!-- Buttons -->
          <div class="text-md-start mt-5">
            <a
              href="https://release.exadrivecdn.com/"
              class="btn btn-light btn-lg shadow lift me-1"
              target="_blank"
            >
              Get The App <i class="fa fa-apple">&nbsp;</i>
              <i class="fa fa-windows"></i>&nbsp;
              <i class="fa fa-linux"></i> &nbsp;<i
                class="fa fa-arrow-right d-none d-md-inline ms-3"
              ></i>
            </a>
            &nbsp;
            <a
              href="https://exa-drive.gitbook.io/exa-protocol-docs"
              class="btn btn-outline-light btn-lg shadow lift me-1"
              target="_blank"
            >
              Documentation
            </a>
          </div>
        </div>
        <div
          class="col-md-6 justify-content-center d-none d-sm-block"
          data-aos="zoom-in"
          data-aos-delay="1000"
          data-aos-once="true"
        >
          <div v-if="referredBy" class="d-flex h-100">
            <div class="text-center p-5 align-self-center mx-auto">
              <div class="card">
                <div
                  class="card-body py-4"
                  style="border: 5px dotted orangered"
                >
                  <Avatar :size="80" variant="beam" :name="referralName" />
                  <h3 class="mt-3">You got referred! 🎉</h3>
                  <p class="text-lg">
                    <b>{{ referralName }}</b> invited you to join Exa Protocol.
                  </p>
                  <hr class="horizontal dark" />

                  <p>
                    You both will earn
                    <b>2500 Exa Compute Points (xCP)</b> after you successfuly
                    rent your machine!
                  </p>
                  <a href="/authentication/signup" class="btn btn-primary"
                    >Sign Up Now!</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- Machine Map -->
  <section>
    <div class="container-fluid p-0 m-0">
      <MachineMap />
    </div>
  </section>

  <!-- Tiered Signup Rewards -->
  <section class="bg-light py-6">
    <TierLadder />
  </section>
  <!-- How to Start Earning with ExaCompute -->
  <section class="py-6 bg-white">
    <div class="container">
      <div class="row h-100">
        <div class="col-md-3 mt-4">
          <h3>Start Earning with ExaCompute in 3 Simple Steps</h3>
        </div>
        <div class="col-md-3 mt-4" data-aos="fade-up" data-aos-once="true">
          <div class="card h-100" data-aos="fade-up" data-aos-once="true">
            <div class="card-body text-center">
              <img
                :src="require('@/assets/img/cli.png')"
                class="img-fluid"
                alt=""
                style="width: 40px"
              />
              <h4 class="mt-4">
                Install the ExaCompute Desktop App & CLI Tool
              </h4>
              <p class="my-4">
                Download our lightweight and secure desktop client or
                command-line tool, both designed to run efficiently in the
                background on your device.
              </p>
              <a
                href="https://release.exadrivecdn.com/"
                target="_blank"
                class="btn btn-primary btn-sm d-block shadow lift me-1"
              >
                Get the App
                <i class="fa fa-arrow-right d-none d-md-inline ms-3"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-4">
          <div class="card h-100" data-aos="fade-up" data-aos-once="true">
            <div class="card-body text-center">
              <img
                :src="require('@/assets/img/connect.png')"
                class="img-fluid"
                alt=""
                style="width: 40px"
              />
              <h4 class="mt-4">Connect Your Device to ExaCompute</h4>
              <p class="my-4">
                Once installed, your device will automatically join the
                ExaCompute network, where it can offer its CPU and GPU resources
                for computational tasks. No additional setup is required.
              </p>
              <a
                href="/dashboard"
                class="btn btn-primary btn-sm d-block shadow lift me-1"
              >
                Get to dashboard
                <i class="fa fa-arrow-right d-none d-md-inline ms-3"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-4">
          <div class="card h-100" data-aos="fade-up" data-aos-once="true">
            <div class="card-body text-center">
              <img
                :src="require('@/assets/img/reward.png')"
                class="img-fluid"
                alt=""
                style="width: 40px"
              />
              <h4 class="mt-4">Start Earning Immediately</h4>
              <p class="my-4">
                Watch your contributions turn into rewards as you earn compute
                tokens for every task your device performs. Plus, keep earning
                points for staying connected, which can be redeemed later.
              </p>
              <a
                href="/dashboard"
                class="btn btn-primary btn-sm d-block shadow lift me-1"
              >
                Get to dashboard
                <i class="fa fa-arrow-right d-none d-md-inline ms-3"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Earnings Calculator -->
  <section class="py-6 bg-light">
    <div class="container">
      <div class="row h-100 mt-4 d-flex justify-content-center">
        <div
          class="col-md-6 col-xs-12 justify-content-center"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <h3>Earn crypto with ExaCompute!</h3>
          <p>
            Curious about how much you can earn by contributing your hardware to
            Exa Protocol's compute network? Our Earnings Calculator gives you an
            instant estimate based on your system specifications.
          </p>
          <img
            :src="require('@/assets/img/token.png')"
            alt=""
            data-aos="flip-left"
            class="img-fluid d-block mx-auto my-5"
            style="width: 120px"
          />
          <ul>
            <li>
              Quick Estimates: Get real-time projections based on your hardware.
            </li>
            <li>
              Optimize Your Contribution: Find the best setup to maximize your
              earnings.
            </li>
            <li>
              Passive Income: Turn your unused computing power into valuable
              rewards.
            </li>
          </ul>
        </div>
        <div class="col-md-6 col-xs-12" data-aos="fade-up" data-aos-once="true">
          <div class="card h-100" data-aos="fade-up" data-aos-once="true">
            <div class="card-body">
              <h3><i class="fa fa-coins"></i> Earnings Calculator</h3>
              <p class="text-sm">
                A modern CPU is required with at least 4 physical cores.
              </p>
              <EarningsCalculator />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Why rent to ExaCompute -->
  <section class="py-6 bg-white">
    <div class="container">
      <div class="row h-100">
        <div class="col-md-3">
          <h3 class="mt-4">Why rent your device to ExaCompute?</h3>
        </div>
        <div class="col-md-3 mt-4" data-aos="fade-up" data-aos-once="true">
          <div class="card h-100" data-aos="fade-up" data-aos-once="true">
            <div class="card-body text-center">
              <img
                :src="require('@/assets/img/earn.png')"
                class="img-fluid"
                alt=""
                style="width: 40px"
              />
              <h4 class="mt-4">Earn xCP</h4>
              <p class="my-4">
                Every time your device is used to perform tasks like AI model
                training, 3D rendering, data processing, or just staus online,
                you earn $XAP tokens. It's a simple and effective way to
                generate passive income while supporting innovative Web3
                applications.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-4">
          <div class="card h-100" data-aos="fade-up" data-aos-once="true">
            <div class="card-body text-center">
              <img
                :src="require('@/assets/img/continuous.png')"
                class="img-fluid"
                alt=""
                style="width: 40px"
              />
              <h4 class="mt-4">Perpetual Rewards</h4>
              <p class="my-4">
                Keep your device connected to ExaCompute and earn Exa Compute
                Points continuously, even when no tasks are being executed.
                These points can later be redeemed for $XAP tokens, allowing you
                to earn even more by simply staying online.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-4">
          <div class="card h-100" data-aos="fade-up" data-aos-once="true">
            <div class="card-body text-center">
              <img
                :src="require('@/assets/img/contribute.png')"
                class="img-fluid"
                alt=""
                style="width: 40px"
              />
              <h4 class="mt-4">Contribute to Decentralization</h4>
              <p class="my-4">
                By renting your device to ExaCompute, you become an essential
                part of a global decentralized network, reducing reliance on
                centralized cloud providers. Your contribution helps create a
                more robust and resilient compute infrastructure.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- FAQ -->
  <section class="py-6 bg-light">
    <div class="container py-5">
      <div class="row">
        <div class="col-md-12"><h3>System Requirements and FAQs</h3></div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5>Minimum Requirements</h5>
              <ul class="text-sm">
                <li>
                  Ubuntu 22.0 or later (LTS Recommended), MaOS (ARM), Windows
                  (x64)
                </li>
                <li>A modern CPU with 4 physical cores</li>
                <li>8 GB RAM</li>
                <li>200GB of free disk space</li>
                <li>20 Mbit Internet Connection</li>
              </ul>

              <h5>Recommended Requirements</h5>
              <ul class="text-sm">
                <li>
                  Ubuntu 22.0 or later (LTS Recommended), MaOS (ARM), Windows
                  (x64)
                </li>
                <li>A modern CPU with 24 physical cores</li>
                <li>32 GB RAM</li>
                <li>Nvidia RTX 4080 or better</li>
                <li>1 TB of free disk space</li>
                <li>100 Mbit Internet Connection</li>
              </ul>

              <hr />
              <p class="text-sm">
                *A system with better performance has a higher chance of getting
                selected for compute tasks.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-md-offset-2">
          <Accordion :value="['0']" multiple>
            <AccordionPanel value="0">
              <AccordionHeader
                >What is $XAP, and how can I use it?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  $XAP is the native token of the Exa Protocol, earned by
                  contributing your device's compute power. You can use $XAP
                  within the Exa Protocol ecosystem, redeem rewards, or trade it
                  on supported platforms.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="1">
              <AccordionHeader
                >How is my device's privacy and security
                ensured?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  Your privacy is a top priority. The Exa Protocol CLI tool runs
                  computations in a secure, isolated environment, ensuring your
                  personal files and data remain completely inaccessible and
                  private.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="2">
              <AccordionHeader
                >Can I pause or stop contributing at any time?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  Yes, you have complete control. You can stop the service or
                  disconnect your device from the network at any time without
                  penalties.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="3">
              <AccordionHeader
                >Does running the CLI tool affect my device's
                performance?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  The CLI tool is designed to be resource-efficient. It uses
                  only idle CPU and GPU resources, ensuring that your device
                  remains responsive for personal use.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="4">
              <AccordionHeader
                >What types of tasks will my device perform?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  Your device will handle a variety of tasks, including AI/ML
                  computations, 3D rendering, data processing, and other
                  distributed workloads that require decentralized compute
                  power.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="5">
              <AccordionHeader
                >Can I see how much I've earned in $XAP and
                xCP?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  You can monitor your earnings and contributions easily through
                  the dashboard.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="6">
              <AccordionHeader>What devices are supported?</AccordionHeader>
              <AccordionContent>
                <p class="m-0 text-sm">
                  ExaCompute supports most modern devices running Ubuntu 22 and
                  above only. Devices with dedicated GPUs will be able to
                  contribute more compute power and earn higher rewards. Check
                  the minimum and recommended system requirements.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="7">
              <AccordionHeader
                >What happens if my device goes offline?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  If your device goes offline, it will stop contributing to the
                  network temporarily, and you won't earn task rewards during
                  that time. However, you can reconnect anytime to resume
                  contributing and earning.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="8">
              <AccordionHeader
                >Is there a limit to how many devices I can
                connect?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  No, you can connect multiple devices to the Exa Protocol,
                  allowing you to maximize your earnings by contributing compute
                  power from all eligible devices.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="9">
              <AccordionHeader
                >How do I redeem my compute points xCP for
                $XAP?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  You can redeem your accumulated connectivity points directly
                  through the dashboard. You will be able to convert xCP into
                  $XAP tokens, USDC, or USDT at the time of TGE.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="10">
              <AccordionHeader
                >What are the system requirements to join?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  The CLI tool is lightweight and supports a wide range of
                  devices. However, higher computational resources (like GPUs)
                  will result in better performance and higher rewards. Check
                  the minimum and recommended system requirements.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="11">
              <AccordionHeader>How do I update the CLI tool?</AccordionHeader>
              <AccordionContent>
                <p class="m-0 text-sm">
                  Updates are rolled out automatically, and the CLI tool will
                  notify you when a new version is available. Installation is
                  simple and ensures you have the latest features and security
                  updates.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="12">
              <AccordionHeader
                >Can I run the CLI tool alongside other
                applications?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  Yes, the tool is designed to run in the background and utilize
                  idle resources, so it won't interfere with your day-to-day
                  activities.
                </p>
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="13">
              <AccordionHeader
                >How do I ensure my device is contributing
                efficiently?</AccordionHeader
              >
              <AccordionContent>
                <p class="m-0 text-sm">
                  Keep your device connected to a stable internet connection and
                  power source. Devices with active GPU and CPU resources will
                  contribute more efficiently.
                </p>
              </AccordionContent>
            </AccordionPanel>
          </Accordion>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- CTA -->
  <section
    class="py-7 py-md-8 bg-gradient-dark"
    :style="{
      'background-image': 'url(' + BGCTA + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
      'background-position': 'bottom',
    }"
  >
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-6 col-lg-6 mt-4">
          <!-- Heading -->
          <h1 class="display-4 text-white">
            Web3 Project? <br />Use Exa Protocol!
          </h1>
          <p class="text-white text-lg mt-3">
            The future of internet is decentralized infra. Get started today
            with decentralized storage using Exa Protocol!
          </p>

          <!-- Button -->
          <a
            href="/authentication/signup"
            class="btn btn-primary btn-lg lift mt-4"
          >
            Get Started &nbsp; <i class="fa fa-arrow-right"></i>
          </a>
        </div>

        <div
          class="col-md-6 col-lg-6 mt-4"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div class="card">
            <div class="card-body">
              <h3>Apply for grants!</h3>
              <p class="text-md">
                Become a part our Accelerate program and get upto 1000 $XAP to
                build your Web3 apps using Exa Protocol!
              </p>
              <div class="text-center">
                <a
                  class="btn btn-primary btn-lg my-4"
                  href="https://forms.gle/KXmK68rQBfdpWKX6A"
                  target="_blank"
                  >Apply Now</a
                >
              </div>

              <h6 class="mt-3">About Accelerate</h6>
              <p>
                Accelerate program is for Web3 projects to build their apps on a
                decentralized cloud infrastructure.
              </p>
              <p class="text-xs mt-4">
                *Accelerate program is for a limited period only.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- FOOTER -->
  <GlobalFooter />
</template>

<script>
import Navbar from "@/components/PageLayout/Navbar.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";
import { mapMutations } from "vuex";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import Avatar from "vue-boring-avatars";

import BGGrid from "@/assets/img/bg-grid.svg";
import BGCube from "@/assets/img/bg-cube.svg";
import BGCTA from "@/assets/img/bg-cta.svg";
import BGFeatures from "@/assets/img/bg-features.png";

import EarningsCalculator from "./components/EarningsCalculator.vue";
import MachineMap from "./components/MachineMap.vue";
import TierLadder from "../home/componentrs/TierLadder.vue";

export default {
  name: "RentMachine",
  components: {
    Navbar,
    GlobalFooter,
    Accordion,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
    Avatar,
    EarningsCalculator,
    MachineMap,
    TierLadder,
  },
  data() {
    return {
      BGGrid: BGGrid,
      BGCube: BGCube,
      BGCTA: BGCTA,
      BGFeatures: BGFeatures,
      referredBy: localStorage.getItem("referredBy") || null,
      referralName: localStorage.getItem("referralName"),
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {},
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>

<style>
.footer-logo {
  width: 60px;
}
.hero-logo {
  width: 80px;
}

.exa-powby {
  width: 160px;
  height: auto;
  position: fixed;
  bottom: 40px;
  right: 0px;
  z-index: 100000;
}
.exa-powby img {
  width: 100%;
  border-radius: 10px 0px 0px 10px;
}
.hero-section {
  /* min-height: 100vh; */
}

.product-logo-hero {
  width: 100px;
  display: block;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.cube {
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #003298;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  -webkit-animation: cube 12s ease-in forwards infinite;
  animation: cube 12s ease-in forwards infinite;
}
.cube:nth-child(2n) {
  border-color: #0051f4;
}
.cube:nth-child(2) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  left: 25vw;
  top: 40vh;
}
.cube:nth-child(3) {
  -webkit-animation-delay: 2s;
  animation-delay: 4s;
  left: 75vw;
  top: 50vh;
}
.cube:nth-child(4) {
  -webkit-animation-delay: 4s;
  animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}
.cube:nth-child(5) {
  -webkit-animation-delay: 6s;
  animation-delay: 8s;
  left: 10vw;
  top: 85vh;
}
.cube:nth-child(6) {
  -webkit-animation-delay: 8s;
  animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}

@-webkit-keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}
</style>
