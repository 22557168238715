<template>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
      <p>
        ⚠️ If you have singed up with Google Auth, skip this. <br />This is only
        meant for users who have singed up with email and password.
      </p>
    </div>
    <div class="card-body pt-0">
      <label class="form-label">Current password</label>
      <input
        class="form-control"
        id="currentPassword"
        type="password"
        placeholder="Current Password"
        v-model="old_password"
      />
      <label class="form-label">New password</label>
      <input
        class="form-control"
        id="newPassword"
        type="password"
        placeholder="New Password"
        v-model="new_password"
        maxlength="24"
        minlength="8"
      />
      <label class="form-label">Confirm new password</label>
      <input
        class="form-control"
        id="confirmPassword"
        type="password"
        placeholder="Confirm password"
        v-model="confirm_password"
        maxlength="32"
        minlength="8"
      />
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0">
        <li>
          <span class="text-sm">Min 8 characters upto 32 characters.</span>
        </li>
        <li>
          <span class="text-sm"
            >At least one number and one special character is recommended.</span
          >
        </li>
      </ul>

      <div class="mb-3 mt-4">
        <p class="font-weight-bold text-sm">Please verify reCaptcha:</p>
        <RecaptchaV2
          @widget-id="handleWidgetId"
          @error-callback="handleErrorCalback"
          @expired-callback="handleExpiredCallback"
          @load-callback="handleLoadCallback"
        />
      </div>
      <ul v-if="validationErrors.length > 0" class="text-sm mt-4 text-danger">
        <li v-for="error in validationErrors" :key="error">{{ error }}</li>
      </ul>
      <soft-button
        class="mt-4"
        color="dark"
        variant="gradient"
        size="sm"
        v-on:click="changePassword()"
        >Change password</soft-button
      >
      <br />
    </div>
    <Toast />
  </div>
</template>
<script>
import SoftButton from "@/components/SoftButton.vue";
import axios from "axios";
import Toast from "primevue/toast";
import { RecaptchaV2, useRecaptcha } from "vue3-recaptcha-v2";
const { handleReset } = useRecaptcha();

export default {
  name: "ChangePassword",
  components: {
    SoftButton,
    Toast,
    RecaptchaV2,
  },
  data() {
    return {
      old_password: "",
      new_password: "",
      confirm_password: "",
      validationErrors: [],
      captchaResponseMessage: "",
      captchaResponse: "",
      widgetID: "",
    };
  },
  methods: {
    handleWidgetId(widgetId) {
      // console.log("Widget ID: ", widgetId);
      this.widgetID = widgetId;
    },
    handleErrorCalback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleExpiredCallback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleLoadCallback(response) {
      // console.log("Load callback", response);
      this.captchaResponseMessage = response;
      this.captchaResponse = true;
    },
    changePassword() {
      //reset validation errors
      this.validationErrors = [];
      if (!this.validatePassword(this.old_password)) {
        this.validationErrors.push(
          "Invalid Old Password. Password must be between 8 and 32 characters."
        );
      }
      if (!this.validatePassword(this.new_password)) {
        this.validationErrors.push(
          "Invalid New Password. Password must be between 8 and 32 characters."
        );
      }
      if (!this.validatePassword(this.confirm_password)) {
        this.validationErrors.push(
          "Invalid Confirm Password. Password must be between 8 and 32 characters."
        );
      }

      if (this.new_password != this.confirm_password) {
        this.validationErrors.push(
          "New password and confirm passwords do not match."
        );
      }

      if (this.validationErrors.length > 0) {
        return;
      }

      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/auth/changePassword",
        data: {
          old_password: this.old_password,
          new_password: this.new_password,
          gRecaptchaResponse: this.captchaResponseMessage,
        },
        headers: { Authorization: localStorage.getItem("jwt") },
      })
        .then((response) => {
          // console.log(response);
          this.$toast.add({
            severity: "success",
            summary: "Success!",
            detail: "Password changed successfully!",
            life: 3000,
          });
          this.old_password = "";
          this.new_password = "";
          this.confirm_password = "";

          handleReset(this.widgetID);
        })
        .catch((error) => {
          handleReset(this.widgetID);
          console.log(error);
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail:
              "Unable to change the password. \n" + error.response.data.errors,
            life: 3000,
          });
        });
    },
    validatePassword(pass) {
      var passwordVal = true;
      if (pass.length < 8 || pass.length > 32) passwordVal = false;

      return passwordVal;
    },
  },
};
</script>

<style>
.hide {
  display: none !important;
}
</style>
