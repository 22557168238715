<template>
  <div
    class="card h-100 bg-white"
    :class="{ 'disabled-badge': tier > currentTier }"
  >
    <div class="ribbon-sm bg-success" v-if="tier == currentTier">Current</div>
    <div class="ribbon-sm bg-success" v-if="currentTier > tier">
      <i class="fa fa-check"></i>
    </div>
    <div
      v-if="tier > currentTier"
      class="lock-overlay d-flex justify-content-center align-items-center"
    >
      <h4 class="fa fa-lock"></h4>
    </div>
    <div class="card-body text-center pt-2 pb-0">
      <img :src="'images/badges/' + icon" alt="" class="img-fluid px-2" />
      <p class="mt-4">{{ name }}</p>

      <p class="text-xs text-dark font-weight-bolder">
        {{ criteria }} >= {{ requiredValue.toLocaleString() }}
      </p>
    </div>

    <div class="card-footer bg-warning p-2 text-dark text-center">
      <p
        v-if="tier > 1"
        class="text-xs font-weight-bold p-0 m-0"
        v-tooltip.right="{
          value: 'From baseline',
          autoHide: false,
        }"
      >
        Earning Bonus: +{{ tierBenefit }}%
      </p>
      <p v-else class="text-xs font-weight-bold p-0 m-0">Earning Baseline</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TierBadge",
  props: {
    name: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "connector.png",
    },
    description: {
      type: String,
      default: null,
    },
    reward: {
      type: Number,
      default: 0,
    },
    criteria: {
      type: String,
      default: null,
    },
    requiredValue: {
      type: Number,
      default: 0,
    },
    currentTier: {
      type: Number,
      default: 11,
    },
    tier: {
      type: Number,
      default: 1,
    },
    tierBenefit: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style scoped>
.disabled-badge {
  filter: grayscale(100%) opacity(0.5);
}
.disabled-badge:hover {
  filter: grayscale(0%) opacity(1);
}
.lock-overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 50px;
  width: 50px;
}

/* HTML: <div class="ribbon">Your text content</div> */
.ribbon-sm {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
.ribbon-sm {
  --f: 0.4em; /* control the folded part*/
  --r: 0.4em; /* control the ribbon shape */

  position: absolute;
  top: 10px;
  right: calc(-1 * var(--f));
  padding-inline: 1em;

  line-height: 1.8;
  /* background: #1f54ff; */
  border-bottom: var(--f) solid #0005;
  border-left: var(--r) solid #0000;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
}
</style>
