<template>
  <!-- applications | settings 16px-->
  <svg
    id="Layer_1"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    fill="none"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="nonzero">
      <path
        :fill="fill"
        class="color-background"
        d="m14.059 16h.941c6.643 0 9-3.5 9-6.5a3.5 3.5 0 0 0 -2.913-3.441 11.564 11.564 0 0 0 .854-2.466 2.961 2.961 0 0 0 -.613-2.476 3.022 3.022 0 0 0 -2.351-1.117h-13.954a3.022 3.022 0 0 0 -2.351 1.117 2.961 2.961 0 0 0 -.613 2.476 11.688 11.688 0 0 0 .852 2.467 3.5 3.5 0 0 0 -2.911 3.44c0 3 2.357 6.5 9 6.5h.933a4.5 4.5 0 0 1 .067.637v3.363a1.883 1.883 0 0 1 -2 2h-3v2h14v-2h-3a1.885 1.885 0 0 1 -2.008-2v-3.363a4.646 4.646 0 0 1 .067-.637zm1.418-2.651a21.035 21.035 0 0 0 4.556-5.349h.467a1.5 1.5 0 0 1 1.5 1.5c0 2.176-1.992 4.5-7.153 4.5a3.6 3.6 0 0 1 .63-.651zm-13.477-3.849a1.5 1.5 0 0 1 1.5-1.5h.464a20.978 20.978 0 0 0 4.551 5.349 3.668 3.668 0 0 1 .63.651c-5.161 0-7.145-2.324-7.145-4.5z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: "12px",
    },
    width: {
      type: String,
      default: "12px",
    },
    fill: {
      type: String,
      default: "#fff",
    },
  },
};
</script>
<style scoped></style>
