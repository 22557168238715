<template>
  <div class="container dashboard-container-fluid">
    <section class="mt-1 pt-2">
      <div class="row mt-3">
        <div class="col-8">
          <h4><i class="fa fa-box"></i> Apps</h4>
          <p class="text-sm">
            This page displays all the apps associated with your account.
          </p>
        </div>
        <div class="col-4">
          <div class="d-flex justify-content-end">
            <div>
              <router-link to="/apps/create" class="btn btn-primary d-block"
                >+ New App</router-link
              >
            </div>
            <div class="ms-2">
              <input
                class="form-control bg-white"
                autocomplete="off"
                placeholder="🔎 Search your apps..."
                v-model="searchTerm"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="col-12 mt-10 mb-10 d-flex justify-content-center"
          v-if="loading"
        >
          <Spinner />
        </div>
        <template v-if="!loading && filterApps().length > 0">
          <div
            class="mb-4 col-lg-4 col-md-4"
            v-for="app in filterApps()"
            :key="app._id"
          >
            <app-card
              :active="app.active"
              :appID="app._id"
              :title="app.appName"
              :description="app.appDescription"
              :lastUpdated="app.updatedOn"
              :apiKey="app.apiKey"
              :apiKeyGenTime="app.apiKeyGenTime"
              :appIdentifier="app.appIdentifier"
              :domainName="app.domainName || ''"
              :domainOwnershipVerified="app.domainOwnershipVerified || false"
              :domainOwnershipVerificationTime="
                app.domainOwnershipVerificationTime || 9999999999999
              "
              :domainSSLGenerated="app.domainSSLGenerated || false"
              :domainSSLGenerationTime="app.domainSSLGenerationTime || null"
            />
          </div>
        </template>
        <template v-if="!loading && filterApps().length == 0 && !searchTerm">
          <div class="mb-4 col-lg-4 col-md-4">
            <PlaceHolderCard
              :title="{ text: 'New App', variant: 'h5' }"
              subtitle="Apps gives you access to storage and compute."
            />
          </div>
        </template>
        <template v-if="!loading && filterApps().length == 0 && searchTerm">
          <div class="col-lg-12 text-center mt-5 pt-4">
            <h1 class="fa fa-warning"></h1>
            <p>Unable to find Apps with this search query.</p>
            <button
              class="btn btn-outline-primary btn-sm"
              @click="
                {
                  searchTerm = null;
                }
              "
            >
              Clear Search
            </button>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import PlaceHolderCard from "./components/PlaceHolderCard.vue";
import AppCard from "./components/AppCard.vue";
import Avatar from "vue-boring-avatars";
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
export default {
  name: "Apps",
  components: {
    AppCard,
    PlaceHolderCard,
    Avatar,
    Spinner,
  },
  data() {
    return {
      showMenu: false,
      apps: [],
      loading: true,
      searchTerm: null,
    };
  },

  mounted() {
    // this.$store.state.isAbsolute = false;
    // this.$store.state.isNavFixed = false;
    // setNavPills();

    //fetch all apps

    axios
      .get(this.$store.getters["getServerURL"] + "/org/apps/getAllApps", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        // console.log(response);
        this.apps = response.data;
        this.apps.reverse();
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    filterApps() {
      if (!this.searchTerm) {
        return this.apps;
      }
      const lowerCaseSearchTerm = this.searchTerm.toLowerCase();

      return this.apps.filter((item) => {
        if (typeof item === "string") {
          return item.toLowerCase().includes(lowerCaseSearchTerm);
        } else if (typeof item === "object") {
          if (
            item.appName
              .toString()
              .toLowerCase()
              .includes(lowerCaseSearchTerm) ||
            item.appDescription
              .toString()
              .toLowerCase()
              .includes(lowerCaseSearchTerm) ||
            item.appIdentifier
              .toString()
              .toLowerCase()
              .includes(lowerCaseSearchTerm)
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
};
</script>
