<template>
  <div
    class="card h-100 bg-white"
    :class="{ 'disabled-badge': !unlocked }"
    v-tooltip.top="{
      value: description,
      autoHide: false,
    }"
  >
    <div
      v-if="!unlocked"
      class="lock-overlay d-flex justify-content-center align-items-center"
    >
      <h4 class="fa fa-lock"></h4>
    </div>

    <div class="ribbon-sm bg-success" v-else>
      <i class="fa fa-check"></i>
    </div>
    <div class="card-body text-center pt-2 pb-0">
      <img :src="icon" alt="" class="img-fluid px-2" />
      <p class="mt-4">{{ name }}</p>

      <p class="text-xs text-dark font-weight-bolder">
        {{ criteria }} >= {{ requiredValue.toLocaleString() }}
      </p>
    </div>

    <div class="card-footer bg-dark p-2 text-white text-center" v-if="reward">
      <p class="text-xs font-weight-bold p-0 m-0">
        Reward: {{ reward.toLocaleString() }} xCP
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    name: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: null,
    },
    reward: {
      type: Number,
      default: 0,
    },
    criteria: {
      type: String,
      default: null,
    },
    requiredValue: {
      type: Number,
      default: 0,
    },
    currentTier: {
      type: Number,
      default: 11,
    },
    unlocked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.disabled-badge {
  filter: grayscale(100%) opacity(0.5);
}
.disabled-badge:hover {
  filter: grayscale(0%) opacity(1);
}
.lock-overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 50px;
  width: 50px;
}
</style>
