<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-12">
        <div class="card">
          <div class="ribbon mt-5 bg-info" v-if="isSuper">
            <i class="fa fa-user-secret"></i>
          </div>
          <div class="ribbon bg-primary" v-if="app.active == 1 && !appLoading">
            Active
          </div>
          <div class="ribbon bg-danger" v-if="app.active == 2 && !appLoading">
            Suspended
          </div>

          <div class="card-body px-4">
            <Skeleton width="25rem" class="mb-2" v-if="appLoading"></Skeleton>
            <template v-else>
              <h2
                v-if="app.domainSSLGenerated"
                class="badge badge-dark text-dark"
              >
                <i class="fa fa-globe text-white"></i> &nbsp;<a
                  :href="'https://' + app.domainName"
                  target="_blank"
                >
                  {{ app.domainName }}</a
                >
              </h2>
              <h3>{{ app.appName || "-" }}'s Bucket</h3>
            </template>

            <div class="row align-items-center">
              <div class="col-12 my-auto">
                <div class="h-100">
                  <Skeleton
                    width="20rem"
                    class="mb-2"
                    v-if="appLoading"
                  ></Skeleton>
                  <p class="text-sm font-weight-bold" v-else>
                    <a
                      :href="
                        'https://' + app.appIdentifier + '.exadrivecdn.com'
                      "
                      target="_blank"
                    >
                      <code
                        >https://{{
                          app.appIdentifier || "-"
                        }}.exadrivecdn.com</code
                      >
                    </a>
                    |
                    <button
                      class="btn btn-link p-0 m-0"
                      @click="copyText(app.appIdentifier + '.exadrivecdn.com')"
                    >
                      <i class="fa fa-copy"></i> Copy Bucket URL
                    </button>
                  </p>

                  <hr />
                  <Skeleton
                    width="30rem"
                    class="mb-2"
                    v-if="appLoading"
                  ></Skeleton>
                  <p class="text-xs" v-else>
                    Storage Policy:
                    <span class="badge badge-primary">redundancy_default</span>
                    &nbsp;
                    <span class="badge badge-primary">all_public</span> &nbsp;
                    <span class="badge badge-primary">overwrite</span> &nbsp;
                    <span class="badge badge-primary">max_file_size_32_MB</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-lg-12 mt-lg-0 mt-4">
        <div class="card mt-4" data-aos="fade-up" data-aos-delay="200">
          <div
            class="card-header border-bottom pb-2 d-flex justify-content-between"
          >
            <div>
              <h6 class="font-weight-bold">
                <span class="fa fa-info-circle"></span> Total {{ fileCount }}
                {{ fileCount == 1 ? "file" : "files" }} in this bucket.
              </h6>
              <p class="text-xs">
                It can take upto 5 mins to update changes to the CDN.
              </p>
            </div>

            <div>
              <button
                data-bs-toggle="modal"
                data-bs-target="#findFileModal"
                class="btn btn-md btn-link mx-1"
              >
                <span class="fa fa-search"></span>&nbsp; Find Files
              </button>

              <button
                class="btn btn-outline-primary btn-md mx-1"
                data-bs-toggle="modal"
                data-bs-target="#uploadZipModal"
              >
                + Upload Zip
              </button>
              <button
                class="btn btn-primary btn-md mx-1"
                data-bs-toggle="modal"
                data-bs-target="#uploadFileModal"
              >
                + Upload File
              </button>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="col-12 p-4 bg-white" v-if="loading">
              <Skeleton class="mb-2"></Skeleton>
              <Skeleton class="mb-2"></Skeleton>
              <Skeleton class="mb-2"></Skeleton>
              <Skeleton class="mb-2"></Skeleton>
              <Skeleton class="mb-2"></Skeleton>
              <Skeleton class="mb-2"></Skeleton>
            </div>

            <div
              class="table-responsive pb-4"
              v-else-if="files.length > 0 && !loading"
            >
              <table class="table">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Filename</th>
                    <th scope="col" style="border-left: 1px solid #ddd">
                      Directory
                    </th>

                    <th scope="col">Size</th>
                    <th scope="col">Public</th>
                    <th scope="col">Uploaded On</th>
                    <th scope="col" style="border-left: 1px solid #ddd">
                      Enable CDN
                    </th>
                    <th scope="col">Copy File URL</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody class="border-bottom">
                  <FileRow
                    :files="files"
                    :domainName="app.domainName"
                    :domainSSLGenerated="app.domainSSLGenerated || false"
                  />
                </tbody>
              </table>
              <Paginator
                class="mt-3"
                :rows="limit"
                :totalRecords="totalFileCount"
                @page="viewPage"
              />
            </div>
          </div>

          <div
            class="card-body text-center mt-3 py-5"
            v-if="files.length == 0 && !loading"
          >
            <font-awesome-icon
              icon="fa-solid fa-triangle-exclamation"
              class="fa-2x"
            />
            <p class="text-center mt-2">No files in this bucket.</p>
            <p class="text-sm mt-n2">
              You can upload files through this page or through the SDK.
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mt-4" v-if="totalFileCount > 0">
        <button
          class="btn btn-outline-primary btn-sm"
          @click="exportToCsv"
          title="Exports all the file data in this Bucket"
        >
          <i class="fa fa-file-csv"></i> Export to CSV
        </button>
        <button
          class="btn btn-link text-danger btn-sm me-2"
          data-bs-toggle="modal"
          data-bs-target="#deleteRecursiveModal"
        >
          <i class="fa fa-trash"></i> Delete all the files
        </button>
      </div>
    </div>
    <Toast />

    <!-- Uplaod File Modal -->
    <div
      class="modal"
      id="uploadFileModal"
      aria-labelledby="uploadFileModal"
      aria-hidden="true"
      v-if="isApp"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Upload File</h5>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span class="fa fa-close"></span>
            </button>
          </div>
          <div class="modal-body pt-3 text-sm">
            <div class="row">
              <div class="col-md-8">
                <ul>
                  <li>
                    File with same name and directory will be overwritten!
                  </li>
                  <li>
                    All files are
                    <span class="badge badge-primary">Public</span>. Be mindful
                    of uploading sensitive files.
                  </li>
                  <li>Max. file size 32MB - for open beta.</li>
                  <li>
                    Directoy name must begin with "/" and <b>NOT</b> end with
                    "/"
                  </li>
                  <li>
                    Directoy name can only contain alphabets, numbers, -, and _.
                    <br />No white spaces are allowed.
                  </li>
                </ul>
              </div>
              <div class="col-md-4 px-6 py-4">
                <img
                  :src="require('@/assets/img/file_icon.svg')"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>

            <form
              id="uploadForm"
              class="mt-4"
              role="form"
              @submit.prevent="uploadFile()"
            >
              <label for="fileDirectory" class="form-label"
                >File Directory | exDFS (Exa Distributed File System)</label
              >
              <div class="input-group mb-3">
                <span
                  class="input-group-text text-xs bg-light text-dark"
                  id="directoy"
                >
                  {{ app.appIdentifier }}.exadrivecdn.com</span
                >
                <input
                  type="text"
                  name="virtualPath"
                  placeholder="Directory must start with /"
                  class="form-control"
                  id="fileDirectory"
                  v-model="virtualPath"
                  aria-describedby="directory"
                />
              </div>

              <label for="formFileLg" class="form-label"
                >Select a file to upload (max. 32MB)</label
              >
              <input
                class="form-control form-control-lg"
                id="formFileLg"
                type="file"
                name="file"
              />
              <button
                type="submit"
                class="btn btn-primary mt-4"
                id="submitFileButton"
              >
                Upload
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Uplaod Zip Modal -->
    <div
      class="modal"
      id="uploadZipModal"
      aria-labelledby="uploadZipModal"
      aria-hidden="true"
      v-if="isApp"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Upload Zip</h5>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span class="fa fa-close"></span>
            </button>
          </div>
          <div class="modal-body pt-3 text-sm">
            <div class="row">
              <div class="col-md-8">
                <ul>
                  <li>
                    Files with same name and directory will be overwritten!
                  </li>
                  <li>
                    All files are
                    <span class="badge badge-primary">Public</span>. Be mindful
                    of uploading sensitive files.
                  </li>
                  <li>Max. ZIP file size 128MB - for open beta.</li>
                  <li>
                    Files with size >32MB inside the ZIP will be skipped for
                    uploading.
                  </li>
                  <li>
                    Zip extration directoy name must begin with "/" and
                    <b>NOT</b> end with "/"
                  </li>
                  <li>
                    Directoy name can only contain alphabets, numbers, -, and _.
                    <br />No white spaces are allowed.
                  </li>
                </ul>
              </div>

              <div class="col-md-4 px-6 py-4">
                <img
                  :src="require('@/assets/img/zip_icon.svg')"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>

            <form
              id="uploadZipForm"
              class="mt-4"
              role="form"
              @submit.prevent="uploadZip()"
            >
              <label for="zipFileDirectory" class="form-label"
                >Zip Extraction Directory | exDFS (Exa Distributed File
                System)</label
              >
              <div class="input-group mb-3">
                <span
                  class="input-group-text text-xs bg-light text-dark"
                  id="directoy"
                >
                  {{ app.appIdentifier }}.exadrivecdn.com</span
                >
                <input
                  type="text"
                  name="virtualPath"
                  placeholder="Directory must start with /"
                  class="form-control"
                  id="zipFileDirectory"
                  v-model="zipVirtualPath"
                  aria-describedby="directory"
                />
              </div>

              <label for="formFileLg" class="form-label"
                >Select a ZIP file to upload (max. 128MB)</label
              >
              <input
                class="form-control form-control-lg"
                id="formFileLg"
                type="file"
                name="file"
                accept=".zip"
              />
              <button
                type="submit"
                class="btn btn-primary mt-4"
                id="submitZipFileButton"
              >
                Upload ZIP
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete File Recursive Modal -->
    <div
      class="modal bg-danger-modal-opacity-05"
      id="deleteRecursiveModal"
      aria-labelledby="deleteRecursiveModal"
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      v-if="isApp"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Delete all the files?
            </h5>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
              v-if="!deletionInProgress"
            >
              <span class="fa fa-close"></span>
            </button>
          </div>
          <div class="modal-body text-center py-5 px-6 mb-4">
            <!-- <img
              src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMWRmbXV1Y2ZscTJueTJseXVtNmlqdGhpYnd2OXpvaXZtbGRvbGVjbCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/WrgAGkGrh0MD1Z2gkO/giphy.gif"
              alt=""
              style="width: 50%"
            /> -->
            <h2><i class="fa fa-trash"></i></h2>
            <h5 class="font-weight-normal mt-4">
              Are you sure you want to delete all the files in this bucket?
            </h5>
            <p class="text-danger text-sm">
              This action cannot be undone. This is the
              <b>only confirmation</b> for this action.
            </p>
            <hr class="horizontal dark my-4" />

            <Inplace :active="showAPIKey">
              <template #display
                >Click here to view the delete button.</template
              >
              <template #content>
                <button
                  class="btn btn-danger"
                  @click="deleteFileRecursive()"
                  :disabled="deletionInProgress"
                >
                  Yes, delete all.
                </button>
                <p v-if="deletionInProgress">
                  <i class="fa fa-spinner fa-spin"></i> Deleting now...
                </p>
              </template>
            </Inplace>
          </div>
        </div>
      </div>
    </div>

    <!-- Find File Modal -->
    <div
      class="modal"
      id="findFileModal"
      aria-labelledby="findFileModal"
      aria-hidden="true"
      v-if="isApp"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              <span class="fa fa-search"></span> Find Files
            </h5>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span class="fa fa-close"></span>
            </button>
          </div>
          <div class="modal-body text-sm">
            <form id="findFileForm" role="form" @submit.prevent="findFile()">
              <label for="fileDirectory" class="form-label"
                >Enter Filename | Only top 10 results will be shown</label
              >
              <div class="input-group mb-3">
                <input
                  type="text"
                  name="fileNameFind"
                  placeholder="Filename..."
                  class="form-control"
                  id="fileNameFind"
                  v-model="fileNameFind"
                  aria-label="File Name"
                  autocomplete="off"
                  aria-describedby="fileNameFind-button"
                  style="height: 42px"
                />
                <button
                  type="submit"
                  class="btn btn-outline-secondary"
                  id="fileNameFind-button"
                >
                  Find
                </button>
              </div>
            </form>

            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive" v-if="findFiles.length > 0">
                  <table class="table">
                    <thead class="table-light">
                      <tr>
                        <th scope="col">Filename</th>
                        <th scope="col">Directory</th>

                        <th scope="col">Size</th>
                        <th scope="col">Public</th>
                        <th scope="col">Uploaded On</th>
                        <th scope="col" style="border-left: 1px solid #ddd">
                          Enable CDN
                        </th>
                        <th scope="col">CDN URL</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody class="border-bottom">
                      <FileRow :files="findFiles" />
                    </tbody>
                  </table>
                </div>
                <div v-else class="text-center">
                  <h6>
                    <span class="fa fa-info-circle"></span> No files to show.
                  </h6>
                  <p>Try finding a different file.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; // optional
import Row from "primevue/row"; // optional
import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
import Toast from "primevue/toast";
import Paginator from "primevue/paginator";
import FileRow from "./components/FileRow.vue";
import Skeleton from "primevue/skeleton";
import Inplace from "primevue/inplace";

export default {
  name: "FileManger",
  components: {
    Column,
    ColumnGroup,
    Row,
    Toast,
    Paginator,
    Inplace,
    FileRow,
    Skeleton,
  },
  async mounted() {
    // this.$store.state.isAbsolute = false;
    // this.$store.state.isNavFixed = false;
    // setNavPills();

    await axios
      .get(
        this.$store.getters["getServerURL"] +
          "/org/apps/file/getUploadedFiles?appID=" +
          this.$route.params.id +
          "&page=" +
          this.page +
          "&limit=" +
          this.limit,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((response) => {
        // console.log(response);
        this.loading = false;
        this.fileCount = response.data.count;
        this.files = response.data.files;
        console.log(response.data.files);
        this.currentPage = response.data.currentPage;
        this.limit = response.data.limit;
        this.totalPages = response.data.pages;
        this.totalFileCount = response.data.count;
        this.isSuper = response.data.super;
      })
      .catch((err) => {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Unable to fetch files.",
          life: 3000,
        });
      });

    //get app
    axios
      .get(
        this.$store.getters["getServerURL"] +
          "/org/apps/getApp/" +
          this.$route.params.id,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((resp) => {
        this.app = resp.data;
        this.isApp = true;
        this.appLoading = false;
      })
      .catch((err) => {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Unable to fetch app.",
          life: 3000,
        });
      });
  },
  data() {
    return {
      appLoading: true,
      fileCount: 0,
      app: {},
      files: [],
      totalPages: 0,
      currentPage: 0,
      limit: 10,
      totalFileCount: 0,
      isApp: false,
      virtualPath: "/",
      zipVirtualPath: "/",
      loading: true,
      findFiles: [],
      fileNameFind: "",
      isSuper: false,
      deletionInProgress: false,
    };
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    viewPage(event) {
      // console.log(event);
      this.currentPage = event.page;
      axios
        .get(
          this.$store.getters["getServerURL"] +
            "/org/apps/file/getUploadedFiles?appID=" +
            this.$route.params.id +
            "&page=" +
            this.currentPage +
            "&limit=" +
            this.limit,
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.files = response.data.files;
          this.currentPage = response.data.currentPage;
          this.limit = response.data.limit;
          this.totalPages = response.data.pages;
          this.totalFileCount = response.data.count;
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Unable to fetch files.",
            life: 3000,
          });
        });
    },
    deleteFile(fileID, fileName, virtualPath) {
      if (
        !confirm(
          "Are you sure you want to delete:\n\n " +
            virtualPath +
            (virtualPath == "/" ? "" : "/") +
            fileName +
            "? \n\nThis action cannot be undone.\nIt can take upto 5 minutes to remove this from CDN cache."
        )
      ) {
        return;
      }
      axios({
        method: "delete",
        url: this.$store.getters["getServerURL"] + "/org/apps/file/deleteFile",
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
        data: {
          fileID: fileID,
          virtualPath: virtualPath,
        },
      })
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            summary: "File Deleted",
            detail: resp.data,
            life: 3000,
          });

          this.fileCount--;
          //delete element from the files object and findFiles object
          for (var i = 0; i < this.files.length; i++) {
            if (this.files[i]._id == fileID) {
              this.files.splice(i, 1);
              break;
            }
          }

          for (var i = 0; i < this.findFiles.length; i++) {
            if (this.findFiles[i]._id == fileID) {
              this.findFiles.splice(i, 1);
              break;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Unable to delete file. \n" + fileID + "\n" + err.response.data,
            life: 2000,
          });
        });
    },
    toggleCDN(fileID, enableCDN) {
      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/org/apps/file/updateFile",
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
        data: {
          fileID: fileID,
          enableCDN: !enableCDN,
        },
      })
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            summary: "",
            detail:
              resp.data.message + "\n" + "CDN Enabled: " + resp.data.enableCDN,
            life: 5000,
          });

          //update file element from the files object and in find files
          for (var i = 0; i < this.files.length; i++) {
            if (this.files[i]._id == fileID) {
              this.files[i].enableCDN = resp.data.enableCDN;
              break;
            }
          }

          for (var i = 0; i < this.findFiles.length; i++) {
            if (this.findFiles[i]._id == fileID) {
              this.findFiles[i].enableCDN = resp.data.enableCDN;
              break;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Unable to change CDN settings for this file. \n" +
              fileID +
              "\n" +
              err.response.data,
            life: 2000,
          });
        });
    },
    uploadFile() {
      const form = document.getElementById("uploadForm");
      document.getElementById("fileDirectory").classList.remove("is-valid");
      document.getElementById("fileDirectory").classList.remove("is-invalid");
      const pathRegex = /^\/([a-zA-Z0-9/_-]*[a-zA-Z0-9_-])?$|^\/$/;
      if (!pathRegex.test(this.virtualPath)) {
        this.$toast.add({
          severity: "error",
          summary: "Path Error",
          detail: "Invalid directory path.",
          life: 3000,
        });
        document.getElementById("fileDirectory").classList.add("is-invalid");
        return;
      }
      document.getElementById("fileDirectory").classList.add("is-valid");
      let formData = new FormData(form);
      formData.append("appID", this.app._id);
      const button = document.getElementById("submitFileButton");
      button.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Loading...`;
      button.disabled = true;
      axios
        .post(
          this.$store.getters["getServerURL"] + "/org/apps/file/uploadFile",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("jwt"),
            },
          }
        )
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Uploaded",
            detail: "File uploaded.",
            life: 2000,
          });
          // console.log(response.data);
          form.reset();
          button.innerHTML = `Upload`;
          button.disabled = false;

          this.fileCount++;

          // console.log("Hello");
          // console.log(response.data);
          //delete same file name from the files array. Traverse opposite as File array is sorted by date.
          for (var i = 0; i < this.files.length; i++) {
            if (
              this.files[i].originalName == response.data.originalName &&
              this.files[i].virtualPath == response.data.virtualPath
            ) {
              this.files.splice(i, 1);
              this.fileCount--;
              break;
            }
          }

          this.files.unshift(response.data);

          // setTimeout(() => {
          //   this.$router.go(this.$router.currentRoute);
          // }, 2000);
        })
        .catch((err) => {
          console.log(err);
          button.innerHTML = `Upload`;
          button.disabled = false;
          this.$toast.add({
            severity: "error",
            summary: "Upload Error",
            detail: err.response.data.errors,
            life: 2000,
          });
        });
    },
    uploadZip() {
      const form = document.getElementById("uploadZipForm");
      document.getElementById("zipFileDirectory").classList.remove("is-valid");
      document
        .getElementById("zipFileDirectory")
        .classList.remove("is-invalid");
      const pathRegex = /^\/([a-zA-Z0-9/_-]*[a-zA-Z0-9_-])?$|^\/$/;
      if (!pathRegex.test(this.zipVirtualPath)) {
        this.$toast.add({
          severity: "error",
          summary: "Path Error",
          detail: "Invalid directory path.",
          life: 3000,
        });
        document.getElementById("zipFileDirectory").classList.add("is-invalid");
        return;
      }
      document.getElementById("zipFileDirectory").classList.add("is-valid");
      let formData = new FormData(form);
      formData.append("appID", this.app._id);
      const button = document.getElementById("submitZipFileButton");
      button.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Loading...`;
      button.disabled = true;
      axios
        .post(
          this.$store.getters["getServerURL"] + "/org/apps/file/uploadZip",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("jwt"),
            },
          }
        )
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Uploaded",
            detail:
              "Zip extracted and " +
              response.data.totalFilesUploaded +
              " files have been uploaded.",
            life: 2000,
          });
          // console.log(response.data);
          form.reset();
          button.innerHTML = `Upload`;
          button.disabled = false;
          console.log(response.data);

          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          button.innerHTML = `Upload`;
          button.disabled = false;
          this.$toast.add({
            severity: "error",
            summary: "Upload Error",
            detail: err.response.data.errors,
            life: 2000,
          });
        });
    },
    findFile() {
      console.log(this.fileNameFind);

      axios({
        method: "get",
        url:
          this.$store.getters["getServerURL"] +
          "/org/apps/file/findFile/" +
          this.$route.params.id +
          "/" +
          this.fileNameFind,
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
        .then((resp) => {
          console.log(resp.data);
          this.findFiles = resp.data;
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to find files",
            life: 2000,
          });
        });
    },
    copyText(text) {
      var context = this;
      navigator.clipboard.writeText(text).then(
        function () {
          context.$toast.add({
            severity: "success",
            summary: "Copied",
            detail: "Copied to clipboard:\n" + text,
            life: 5000,
          });
        },
        function (err) {
          context.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to copy CDN URL:\n" + text,
          });
        }
      );
    },
    deleteFileRecursive() {
      if (
        !confirm(
          "Are you absolutely sure that you want to delete all the files?"
        )
      ) {
        return;
      }
      this.deletionInProgress = true;
      axios({
        method: "delete",
        url:
          this.$store.getters["getServerURL"] + "/org/apps/file/deleteAllFiles",
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
        data: {
          appID: this.$route.params.id,
        },
      })
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            summary: "All the files have been deleted!",
            detail: resp.data,
            life: 3000,
          });
          setTimeout(() => {
            location.reload();
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "We couldn't complete the file deletion process. Some files may still remain undeleted.",
            life: 5000,
          });
          setTimeout(() => {
            location.reload();
          }, 5000);
        });
    },
    exportToCsv() {
      this.$toast.add({
        severity: "info",
        summary: "Processing",
        detail: "Preparing CSV export...",
        life: 3000,
      });

      axios({
        method: "get",
        url:
          this.$store.getters["getServerURL"] +
          "/org/apps/file/exportFilesToCSV/" +
          this.$route.params.id,
        headers: { Authorization: localStorage.getItem("jwt") },
        responseType: "blob", // Important for handling file downloads
      })
        .then((response) => {
          // Create a blob URL and trigger download
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `bucket-files-${this.app.appName}.csv`);
          document.body.appendChild(link);
          link.click();

          // Clean up
          window.URL.revokeObjectURL(url);
          link.remove();

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Files exported to CSV successfully",
            life: 3000,
          });
        })
        .catch((err) => {
          console.error(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to export files to CSV. Please try again later.",
            life: 5000,
          });
        });
    },
  },
};
</script>

<style>
.p-paginator-page.p-paginator-page-selected {
  background: lightgray !important;
  color: black !important;
}

td {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* HTML: <div class="ribbon">Your text content</div> */
.ribbon {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.ribbon {
  --f: 0.5em; /* control the folded part*/
  --r: 0.8em; /* control the ribbon shape */

  position: absolute;
  top: 20px;
  right: calc(-1 * var(--f));
  padding-inline: 2em;

  line-height: 1.8;
  /* background: #1f54ff; */
  border-bottom: var(--f) solid #0005;
  border-left: var(--r) solid #0000;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
}

.bg-danger-modal-opacity-05 {
  background: rgba(64, 0, 0, 0.8);
}
</style>
