<template>
  <router-link
    :data-bs-toggle="collapse ? 'collapse' : ''"
    :to="collapse ? `#${collapseRef}` : collapseRef"
    :aria-controls="collapseRef"
    aria-expanded="false"
    class="nav-link"
    :class="{ achievements: collapseRef == '/achievements' }"
    v-bind="$attrs"
    type="button"
    @click="isExpanded = !isExpanded"
    :target="target"
  >
    <div
      class="text-center bg-white shadow icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center"
      :class="{ 'achievements-icon-bg': collapseRef == '/achievements' }"
    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text ms-3">{{ navText }}</span>
  </router-link>
  <div :id="collapseRef" class="collapse">
    <slot name="list"></slot>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SidenavCollapse",
  props: {
    collapseRef: {
      type: String,
      required: true,
    },
    navText: {
      type: String,
      required: true,
    },
    collapse: {
      type: Boolean,
      default: true,
    },
    target: {
      type: String,
      default: "_self",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>

<style scoped>
.nav-link {
  cursor: pointer;
}
.nav-item .active {
  background-color: #e3edff !important;
}
.active.achievements {
  background-color: #ffead4 !important;
}

.navbar-vertical
  .navbar-nav
  > .nav-item
  .nav-link.active
  .icon.achievements-icon-bg {
  background: #ff8000 !important;
}
</style>
