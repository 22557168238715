const config = {
     development: {
          apiUrl: 'http://localhost:3000',
          googleClientId: '10732367706-nqd025a2rfdq6elsf8po7o2mojst0fo0.apps.googleusercontent.com'
     },
     production: {
          apiUrl: 'https://exadrive-phoenix-server.exaprotocol.com',
          googleClientId: '10732367706-nqd025a2rfdq6elsf8po7o2mojst0fo0.apps.googleusercontent.com'
     }
};

const env = process.env.NODE_ENV;
console.log(env);
export default config[env]; 