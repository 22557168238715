<template>
  <!-- -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- -->
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mt-1 col-12 text-center">
          <p class="mb-0 text-muted text-bold text-sm">
            Created with <span class="fa fa-heart text-danger"></span> by team
            at Exa Protocol. Copyright ©
            {{ new Date().getFullYear() }} exaprotocol.com. All rights reserved.
          </p>
          <p class="mb-0 text-secondary text-xs">
            <i class="fa fa-warning"></i> Exa Protocol Management Console is
            currently open beta. Expect experimental features, ephemeral data
            storage, and CDN downtime.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
